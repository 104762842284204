import React from "react";
import {Box} from "@material-ui/core";
import styled from "styled-components/macro";
import SocialView from "../../Social";

const Wrap = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const FooterView = () => {
    return (
        <Wrap>
            <SocialView />
        </Wrap>
    );
};

export default FooterView;
