import React from "react";
import styled from "styled-components/macro";
import QrImage from "../../../../images/QR.svg";

const QrWrap = styled.div`
    display: flex;
    justify-content: center;
`;

const QrImg = styled.img`
    width: 100px;
`;

export function QrCodeView() {
    return (
        <QrWrap>
            <QrImg src={QrImage} />
        </QrWrap>
    );
}
