export const UK_PRIVACY_POLICY = `
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Вітаємо у розділі Політики конфіденційності TapeMeet. Дякуємо, що приділяєш час на ознайомлення з нею.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:10pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Mи хочемо, щоб у тебе було розуміння про те, яку інформацію ми збираємо, чому ми її збираємо, як вона використовується та який ти маєш вибір щодо своїх даних. У цій політиці ми описуємо наші методи щодо конфіденційності простою мовою та намагаємося максимально уникати юридичної та технічної термінології.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ДАТА НАБРАННЯ ЧИННОСТІ:</span><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">01.08.2021</span><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;р</span>
</p>
<h2 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 4pt 0pt;">
    <span style="font-size:17pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для користувачів з Каліфорнії</span>
</h2>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Детальніше про права на конфіденційність для Каліфорнії див.&nbsp;</span><a
        href="/california-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Заяву про конфіденційність для Каліфорнії</span></a><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Хто ми</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Як оператори персональних даних, що збираються та обробляються в рамках програми TapeMeet, виступає фізична особа Сергій Лизун. Позначається у тексті цієї Політики як &laquo;ми&raquo;, &laquo;нас&raquo;.</span>
</p>
<p dir="ltr"
   style="line-height:1.542857142857143;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:-11pt 0pt -1pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ви можете зв&apos;язатися з уповноваженим із захисту персональних даних TapeMeet, написавши на&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:15pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. Де застосовується наша Політика конфіденційності</span>
</h3>
<p dir="ltr"
   style="line-height:1.542857142857143;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:-5pt 0pt -1pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ця політика конфіденційності поширюється на додаток TapeMeet.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:15pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. Інформація, яку ми збираємо</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Звичайно, ми не зможемо допомогти тобі завести потрібні знайомства без певної інформації про тебе, наприклад основних даних профілю та типажу людей, з якими ти хочеш спілкуватися. Ми також збираємо інформацію про те, як ти користуєшся нашими сервісами, як-от доступ до журналів, і дані від третіх сторін, коли ти, наприклад, заходиш на наші сервіси через свій акаунт у соціальній мережі чи завантажуєш інформацію зі свого акаунту в соцмережі, щоб заповнити профіль. Додаткова інформація наведена нижче.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Інформація, яку ти нам надаєш</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Використовуючи TapeMeet, ти погоджуєшся надавати нам певну інформацію, включно з наступною:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Створюючи акаунт, ти повідомляєш нам принаймні про свій номер телефону та імейл, а також деяку основну інформацію, яка необхідна для роботи застосунку, як-от гендер або дата народження.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Заповнюючи профіль, ти можеш поділитися додатковою інформацією, такою як дані у біографії, інтереси й інші подробиці про себе, а також таким контентом, як фото та відео. Щоб додати певні матеріали, наприклад фотографії чи відео, ти можеш надати нам доступ до своєї камери чи фотоальбому.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Підписуючись на платну послугу чи купуючи безпосередньо у нас (а не через таку платформу, як iOS або Android), ти надаєш нам або нашому постачальнику платіжних послуг таку інформацію, як номер своєї дебетової чи кредитної картки або інші фінансові відомості.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Беручи участь в опитуваннях, дослідженнях ринку чи входячи до фокус-груп, ти ділишся з нами своїми думками про наші продукти та сервіси, відповідями на наші питання та рекомендаціями.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо ти береш участь у наших рекламних акціях, заходах або конкурсах, то ми збираємо інформацію, яку ти використовуєш для реєстрації чи участі.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо ти звертаєшся до нашої служби підтримки клієнтів, ми збираємо інформацію, яку ти надаєш нам під час цієї взаємодії.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо ти ділишся з нами інформацією про інших людей (наприклад, якщо ти використовуєш контактні дані друга для певної функції), ми обробляємо цю інформацію від твого імені, щоб виконати твій запит.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Звичайно, ми також обробляємо дані з твоїх чатів з іншими користувачами та контент, який ти публікуєш, як це потребується для роботи сервісів.</span>
        </p>
    </li>
</ul>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Інформація, яку ми отримуємо від інших</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Окрім інформації, яку ти надаєш безпосередньо нам, ми отримуємо інформацію про тебе від інших джерел, зокрема таких:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Користувачі</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Користувачі можуть надавати інформацію про тебе, використовуючи наш додаток, наприклад, якщо вони взаємодіють із тобою чи надсилають на тебе скаргу.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Соціальні мережі</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Ти можеш поділитися з нами інформацією через свій акаунт у соціальній мережі, наприклад, якщо ти створиш акаунт у TapeMeet і увійдеш у нього через соціальну мережу чи інший акаунт (як-от Facebook), або надіслати до нашого сервісу інформацію, наприклад світлини, зі свого акаунту в соціальній мережі (Instagram, Spotify тощо).</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Інші партнери</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Ми можемо отримувати інформацію про тебе від наших партнерів, наприклад, коли реклама&nbsp;</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TapeMeet</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;публікується на сервісі партнера (у цьому випадку партнер може передавати подробиці про результати кампанії). Якщо закон дозволяє, ми також можемо отримувати інформацію про підозри в правопорушеннях або вже скоєні правопорушення від третіх сторін, тому що ми намагаємося зробити наш застосунок максимально безпечними для користувачів.</span>
        </p>
    </li>
</ul>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Інформація, зібрана при вашому користуванні нашими сервісами</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Коли ти використовуєш наш додаток, генеруються технічні дані про функції, які ти використовуєш, спосіб їхнього використання та пристрої, з яких ти користуєшся нашими сервісами. Нижче наведені подробиці:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Відомості про використання</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Використання застосунку генерує дані про твою активність на них, наприклад, як ти ними користуєшся (як-от час входу, використані функцій, дії, показана тобі інформація, адреса вебсторінок із вихідним посиланням і реклама, з якою у тебе була взаємодія) та як взаємодієш з іншими користувачами (наприклад, користувачі, з якими ти спілкуєшся та взаємодієш, коли саме це трапляється, кількість відправлених і отриманих повідомлень).</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Відомості про пристрій</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Ми збираємо інформацію від пристрою чи пристроїв, з яких ти користуєшся нашими сервісами, та про них, включно з даними про апаратне та програмне забезпечення, такими як IP-адреса, ідентифікатор і тип пристрою, налаштування та характеристики застосунків, аварійні завершення роботи застосунків, рекламні ідентифікатори (випадково згенеровані числа, які можна скинути в налаштуваннях пристрою), ідентифікатори, пов&rsquo;язані з файлами cookie чи іншими технологіями, які можуть однозначно ідентифікувати пристрій або браузер.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Інші відомості за твоєю згодою</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Якщо ти надаєш згоду, то ми можемо збирати інформацію про твою точну геолокацію (довгота та широта). Збір даних про геолокацію може відбуватися на фоні, навіть коли ти не користуєшся додатком, якщо твоя згода чітко дозволяє такий збір. Якщо ти не надаси дозвіл на збір інформації про твою точну геолокацію, ми не будемо її збирати. Таким же чином, якщо ти надаси дозвіл, ми зможемо збирати фото та відео (наприклад, якщо ти захочеш опублікувати світлину чи відео або скористатися функціями з трансляцією).</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. Файли cookies та інші технології збору даних</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми використовуємо та можемо дозволити іншим використовувати файли cookie та подібні технології (вебмаяки, піксельні теги, тощо), щоб розпізнавати тебе та/або твій пристрій чи пристрої. Щоб докладніше дізнатися про причини їхнього використання та способи ними керувати, ознайомся з нашою&nbsp;</span><a
        href="/cookie-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Політикою щодо файлів cookie</span></a><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;" id="section-5">5. Як ми використовуємо інформацію</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Основна причина, з якої ми використовуємо твою інформацію, &ndash; це надання та покращення нашого застосунку. Крім того, ми використовуємо твою інформацію, щоб захистити тебе, а також щоб показувати рекламу, яка може тебе зацікавити. Нижче ми детально пояснили різні причини для використання твоєї інформації та навели практичні приклади.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:2pt;padding:2pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для адміністрування твого акаунта та забезпечення роботи застосунка</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <h4 dir="ltr"
            style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Створення акаунта та управління ним</span>
        </h4>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Надання тобі підтримки та відповідей на запити</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Виконання твоїх транзакцій</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Спілкування з тобою про наш сервіс</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для допомоги вам у знайомстві з іншими користувачами</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Рекомендування тобі інших користувачів</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Показ профілів користувачів одне одному</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для надання нових послуг TapeMeet</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Реєстрація та відображення твого профілю в нових функціях і додатках TapeMeet</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Адміністрування твого акаунта в цих нових функціях і додатках</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для керування рекламними та маркетинговими кампаніями</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Керування розіграшами, конкурсами, знижками та іншими пропозиціями</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Здійснення рекламних кампаній і вимір їхньої ефективності на наших сервісах, а також ефективності маркетингових кампаній, які рекламують Tinder поза нашими сервісами</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Зв&apos;язок з тобою стосовно продуктів або послуг, які, на нашу думку, можуть теб</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">е зацікавити</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для покращення наших сервісів і розробки нових</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Адміністрування фокус-груп, маркетингових досліджень і опитувань</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Перегляд взаємодій із командою підтримки клієнтів, щоб покращити якість сервісу</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Розуміння того, як користувачі зазвичай використовують сервіси, щоб поліпшити їх (наприклад, ми можемо вирішити змінити вигляд і стиль якоїсь функції чи навіть значно її переробити залежно від реакції користувачів)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Розробка нових функцій і сервісів (наприклад, ми можемо вирішити створити нову функцію на основі інтересів за запитами користувачів)</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для попередження та виявлення шахрайства чи іншої незаконної або несанкціонованої діяльності та боротьби з ними</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Пошук і усунення порушень (поточних, підозрюваних або ймовірних) наших</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Умови використання</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, зокрема через перегляд скарг і взаємодій між користувачами</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Краще розуміння та розробка протидії порушенням наших&nbsp;</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Умови використання</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Збереження даних, пов&apos;язаних із порушенням наших</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Умови використання</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, щоб попередити повторні випадки</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Забезпечення дотримання чи здійснення наших прав, наприклад наших</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Умови використання</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Зв&apos;язок з особами, які надсилають скарги, щоб повідомити їм про наші дії щодо їхньої скарги</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для забезпечення дотримання законодавства</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Відповідність юридичним вимогам</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Допомога правоохоронним органам</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для обробки твоєї інформації згідно з цією політикою конфіденційності ми покладаємося на такі правові основи:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Надання наших послуг.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Причина, з якої ми оброблюємо твою інформацію для вищенаведених цілей &ndash; це виконання укладеного між нами договору. Наприклад, поки ти використовуєш наш додаток для будування важливих стосунків, ми використовуємо твої дані, щоб підтримувати твій акаунт і профіль, робити його доступним для перегляду, рекомендувати тобі інших користувачів і надавати наші безоплатні та платні функції тобі та іншим користувачам будь-яким іншим чином.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Законні інтереси.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Ми обробляємо твої дані для вищенаведених цілей на основі наших законних інтересів. Наприклад, ми аналізуємо поведінку користувачів у застосунку, щоб постійно вдосконалювати наші пропозиції, ми рекомендуємо пропозиції, які, на нашу думку, можуть тебе зацікавити та рекламуємо наші власні сервіси, ми обробляємо інформацію, щоб посилити безпеку наших користувачів, і обробляємо дані, коли потрібно забезпечити дотримання наших прав, допомогти правоохоронним органам і надати нам можливість захистити себе у випадку судового позиву.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Відповідність застосовним законам і положенням.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Ми обробляємо твої дані, коли ми маємо відповідати вимогам застосовних законів і положень та підтвердити цю відповідність застосовним законам і положенням.&nbsp;</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Згода.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Якщо ти вирішуєш надати нам інформацію, яка може вважатися &laquo;приватною&raquo; чи &laquo;конфіденційною&raquo; у певних юрисдикціях, наприклад інформація про сексуальну орієнтацію, ти надаєш згоду на обробку цієї інформації нами згідно з цією Політикою конфіденційності. Час від часу ми можемо просити твоєї згоди на збір конкретної інформації, такої як твоя точна геолокація, або використовувати твою інформацію з певних конкретних причин. У деяких випадках ти зможеш відкликати свою згоду, змінивши налаштування (наприклад, згоду щодо збору даних про твою точну геолокацію) чи видаливши зміст (наприклад, якщо вказана в профілі інформація може вважатися &laquo;приватною&raquo; чи &laquo;конфіденційною&raquo;). У будь-якому випадку ти можеш відкликати свою згоду будь-коли, зв&apos;язавшись із нами.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;" id="section-6">6. Як ми ділимося інформацією</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:6pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Наша мета &ndash; допомогти тобі будувати важливі стосунки з іншими людьми, тому найчастіше ми ділимося інформацією користувачів саме з іншими користувачами. Окрім того, ми надаємо доступ до деякої інформації про користувачів постачальникам послуг і партнерам, які допомагають нам підтримувати наш додаток і в деяких випадках правовим органам. Нижче наведені подробиці про те, як твоя інформація надається іншим.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Іншим користувачам</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ти надаєш інформацію іншим користувачам, коли добровільно розкриваєш її в застосунку (включно з інформацію, що міститься у загальнодоступному профілі). Будь ласка, прояви обачність і переконайся, що ти дійсно не проти поділитися певною інформацією.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо ти вирішиш обмежити доступ аудиторії до частини або всього профілю чи до певного змісту або інформації про себе, ці дані будуть видимими відповідно до твоїх налаштувань.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо хтось надішле скаргу, що пов&apos;язана з тобою, ми можемо повідомити людині, яка надіслала цю скаргу, про заходи (за наявності), які ми вжили в результаті скарги.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Нашим постачальникам послуг і партнерам</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми користуємося послугами третіх сторін, які допомагають нам працювати та вдосконалювати наш застосунок. Ці треті сторони допомагають нам із різними завданнями, включно з розміщенням й обслуговуванням даних, аналітикою, підтримкою клієнтів, маркетингом, рекламою, обробкою платежів та операцій з безпеки. Ми також надаємо інформацію партнерам, які розповсюджують та допомагають нам рекламувати TapeMeet.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми дотримуємося суворого процесу перевірки, перш ніж залучати будь-якого постачальника послуг або працювати з будь-яким партнером. Наші постачальники послуг і партнери мають погодитися на суворі зобов&apos;язання щодо конфіденційності.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для корпоративних транзакцій</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми можемо передавати твою інформацію, якщо ми повністю чи частково беремо участь у злитті, продажу, придбанні, реалізації активів, реструктуризації, реорганізації, розпуску, банкрутстві чи іншій зміні власності або управління.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Правоохоронним органам / на вимогу закону</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми можемо розкрити твою інформацію, якщо це обґрунтовано необхідно:&nbsp;</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">а) на вимогу судового процесу, наприклад згідно із судовим наказом, повісткою до суду чи ордером на обшук, через розслідування уряду чи правоохоронних органів або інші юридичні вимоги;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">б) щоб допомогти запобігти скоєнню злочину чи виявити його (у кожному випадку залежить від застосовного закону);&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">в) для захисту якоїсь особи.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для забезпечення законних прав</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми також можемо надавати інформацію:&nbsp;</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">а) якщо розголошення пом&apos;якшить нашу відповідальність у фактичному чи можливому позові;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">б) в якості необхідного захисту наших законних прав і законних прав наших користувачів, ділових партнерів або інших зацікавлених сторін;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">в) для виконання наших домовленостей з тобою;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">г) для розслідування, запобігання чи вживання інших заходів щодо незаконної діяльності, можливого шахрайства чи інших правопорушень.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">За твоєю згодою чи запитом</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми можемо попросити твоєї згоди на передачу твоєї інформації третім сторонам. У таких випадках ми чітко пояснимо, чому ми хочемо передати цю інформацію.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми можемо використовувати та передавати неособисту інформацію (тобто інформацію, яка сама по собі не ідентифікує вас, зокрема інформацію про пристрої, загальну демографічну інформацію, загальні дані про поведінку, місцеположення без визначення особи), а також особисту інформацію в гешованому вигляді, в якому її не може прочитати людина, за будь-яких вищезазначених обставин. Ми також можемо надати цю інформацію третім сторонам (зокрема рекламодавцям) для розробки та надання таргетованої реклами в наших сервісах та на веб-сайтах чи в додатках третіх сторін, а також для аналізу та звітності про рекламу, яку ви бачите. Ми можемо поєднувати цю інформацію з додатковою неособистою або особистою інформацією у гешованому вигляді, в якому її не може прочитати людина, зібраною з інших джерел. Додаткову інформацію про використання нами файлів cookie та подібні технології можна знайти в нашій&nbsp;</span><a
                href="/cookie-policy" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Політиці щодо файлів cookie</span></a>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Передача даних іншим країнам</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Надання інформації, яке описано в&nbsp;</span><a
        href="#section-6"
        style="text-decoration:none;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">розділі 6</span></a><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, поширюється на передачу даних до Сполучених Штатів Америки та інших юрисдикцій, які можуть мати відміні закони щодо обробки даних. Коли ми передаємо особисту інформацію поза ЄЕЗ, Сполученим Королівством, Швейцарією чи іншими країнами, закони про захист даних яких вважаються належними згідно з Європейською комісією чи іншим компетентним урядовим органом, ми використовуємо стандартні договірні положення &ndash; це зобов&apos;язання між компаніями, які передають особисті дані, що вимагають від них охороняти конфіденційність і безпеку твоїх даних або інший належний механізм передачі даних.&nbsp;</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Ваші права</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:6pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми хочемо, щоб у тебе був контроль над своєю інформацією, тому нагадуємо про такі доступні можливості та інструменти:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Засоби доступу / оновлення в сервісі.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Інструменти та налаштування акаунту допоможуть тобі отримати доступ до інформації, яку ти нам надаєш і яка пов&apos;язана із твоїм акаунтом безпосередньо в застосунку, а також виправити чи вилучити таку інформацію. Якщо в тебе виникли питання щодо цих інструментів і налаштувань, звернися до нашої&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">служби підтримки</span></a><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Дозволи на пристрої.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Мобільні платформи можуть мати системи дозволів для певних типів даних і сповіщень, таких як контакти телефона, зображення, сервіси локації, push-сповіщення та рекламні ідентифікатори. Ти можеш змінити налаштування пристрою, надавши згоду чи відмовивши в згоді на збір і обробку відповідної інформації чи показ відповідних сповіщень. Звичайно, якщо не надати згоду, певні сервіси можуть втратити свою функціональність.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Видалення.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Зупинити весь збір інформації у застосунку можна, видаливши його шляхом стандартного процесу видалення на твоєму пристрої. Зверни увагу: видалення застосунку НЕ припиняє дію акаунту. Щоб закрити свій акаунт, скористайся відповідною функцією на сервісі.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Закриття акаунту.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Акаунт можна закрити, скориставшись відповідною функцією безпосередньо в додатку.</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми також хочемо, щоб наші користувачі знали про свої права на конфіденційність. Ось кілька важливих моментів, які слід пам&rsquo;ятати:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Перегляд інформації про себе.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Застосовні закони щодо конфіденційності можуть дозволяти тобі переглядати свою особисту інформацію, яку ми зберігаємо. Ти можеш скористатися цим правом, надіславши відповідний запит</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">тут</span></a><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Оновлення інформації.</span><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Якщо ти вважаєш, що наша інформація про тебе є неточною або що ми більше не маємо права використовувати її, і бажаєш зробити запит на її виправлення чи видалення, заперечуєш або забороняєш її обробку, звернись до&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">нас</span></a><span
                style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Задля твого захисту та захисту всіх наших користувачів ми можемо попросити надати підтвердження особи, перш ніж відповідати на вищезазначені запити.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Зверни увагу: ми можемо відхилити запити, включно з ситуаціями, коли ми не можемо встановити твою особу, коли запит є незаконним або недійсним або коли він може порушити комерційні таємниці, інтелектуальну власність, конфіденційність або інші права іншої особи. Якщо ти бажаєш отримати інформацію стосовно іншого користувача, наприклад копію будь-яких повідомлень, отриманих від нього через наш застосунок, іншому користувачеві доведеться зв&rsquo;язатися з нами, щоб надати письмову згоду перед оприлюдненням цієї інформації. Ми також можемо попросити цю людину надати підтвердження особи, перш ніж відповідати на такий запит.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Крім того, можливо, нам не вдасться виконати певні запити на заперечення чи обмеження обробки особистої інформації, особливо якщо такі запити завадять нам надалі надавати тобі наші послуги. Наприклад, ми не зможемо надавати наші послуги, якщо не матимемо твоєї дати народження, бо тоді ми не зможемо гарантувати, що тобі вже виповнилося 18 років.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">У деяких країнах, включно з Європейською економічною зоною та Сполученим Королівством, ти маєш право подати скаргу до відповідного органу із захисту даних, якщо маєш сумніви щодо того, як ми обробляємо твою особисту інформацію.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. Як довго ми зберігаємо твої дані</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми зберігаємо твою особисту інформацію тільки поки вона нам потрібна для законних цілей у бізнесі (як викладено у&nbsp;</span><a
        href="#section-5"
        style="text-decoration:none;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">розділі 5</span></a><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">) і згідно з чинним законодавством. Якщо ти захочеш припинити користуватися нашим додатком, ти зможеш закрити свій акаунт. Тоді інші користувачі не будуть бачити твій профіль. Зверни увагу: ми закриваємо акаунт автоматично, якщо користувач не виявляє активність протягом двох років. Після закриття акаунту ми видалимо особисту інформацію, як зазначено нижче:</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.Для безпеки наших користувачів ми впроваджуємо період збереження інформації, який триває три місяці після закриття акаунту чи один рік після блокування акаунту. Протягом цього періоду ми зберігаємо твою інформацію на випадок, якщо вона знадобиться для розслідування незаконних чи шкідливих дій. Збереження інформації в цей період ґрунтується на нашому законному інтересі та інтересі потенційних потерпілих, які є третьою стороною.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.Після закінчення цього періоду, ми видалимо твої дані, зберігаючи тільки обмежену інформацію для таких цілей:</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 12pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">а) Ми зберігаємо обмежені дані для виконання правових зобов&apos;язань щодо збереження даних. Зокрема, ми зберігаємо дані транзакцій протягом 10 років, щоб відповідати податковим і бухгалтерським вимогам закону, дані кредитних карт &ndash; протягом часу, коли користувач може оскаржити транзакцію, і &laquo;дані трафіку&raquo; / журнали &ndash; протягом одного року для відповідності правовим зобов&apos;язанням щодо збереження даних. Крім того, ми зберігаємо інформацію щодо згоди, яку надають користувачі, протягом п&apos;яти років у якості доказу нашої відповідності чинному законодавству.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">б) Ми зберігаємо обмежену інформацію на основі нашого законного інтересу: ми зберігаємо записи обслуговування клієнтів, дані підтримки, неточну локацію завантаження / покупки протягом п&apos;яти років для підтримки наших рішень щодо обслуговування клієнтів, забезпечення наших прав і надання нам можливості захистити себе у випадку позову; інформацію щодо існування минулих акаунтів і передплат, яку ми видаляємо через три роки після закриття твого останнього акаунту, щоб забезпечити належне та точне фінансове прогнозування та звітність; дані профілю протягом одного року на випадок потенційного судового процесу, для встановлення, здійснення чи захисту правових претензій; дані, завдяки яким ми перешкоджаємо заблокованим користувачам створювати нові акаунти, доки це необхідно для підтримки безпеки та найважливіших інтересів наших користувачів.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">в) Ми зберігаємо інформацію на основі нашого законного інтересу за наявності неврегульованої чи потенційної проблеми, позову чи спірного питання, що вимагає від нас зберігати інформацію (зокрема, якщо ми отримуємо дійсну юридичну повістку чи запит із проханням зберегти дані (у цьому випадку ми матимемо зберігати дані для виконання наших правових зобов&apos;язань) або якщо дані є необхідними в судовому процесі з інших причин).</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10. Конфіденційність стосовно дітей</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Наш застосунок доступен лише особам, яким уже виповнилося 18 років. Ми не допускаємо користувачів, які не досягли 18 років, для корістування нашім додатком. Якщо ти підозрюєш, що користувачу менше 18 років, будь ласка, надійшли скаргу в нашу&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">службу підтримки</span></a><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.</span><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Зміни у Політиці конфіденційності</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми завжди шукаємо нові та інноваційні способи, які допоможуть тобі будувати важливі стосунки, та намагаємося своєчасно оновлювати роз&apos;яснення щодо наших дій із даними. Тому з часом ця політика може змінитися. Ми повідомимо тобі до того, як суттєві зміни набудуть чинності, щоб у тебе був час їх переглянути.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12. Як з нами зв&apos;язатися</span>
</p>
<p><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#202124;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо у тебе виникнуть питання щодо цієї політики конфіденційності, ти можеш зв&apos;язатися з нами&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a><span
        style="font-size:13pt;font-family:'Proxima Nova',sans-serif;color:#505965;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>

`;
