import {Locale} from "./types";

export const REST_URL =
    process.env.NODE_ENV === "development" ? "http://192.168.0.186:443" : "https://api.tapemeet.com";

export const COLOR = {
    white: "#ffffff",
    gray: "#f6f7f9",
    black: "#000000",
    grayDark: "#e9e8ea",
    text: "#4a494a",

    facebook: "#3b5898",

    primaryGradient: "#eb596c", // we use this color for gradient logo
    primary: "#EC3A48",

    secondary: "#0694e2",
    success: "#11e19d",
};

export const OPACITY = 0.5;

export const BREAKPOINT = {
    mobile: 767,
    tablet: 1024,
};

export const LANGUAGE = {
    [Locale.EN]: "English",
    [Locale.PT]: "Português",
    [Locale.UK]: "Українська",
    [Locale.RU]: "Русский",
};

export const LOCALE_LANGUAGE = {
    [Locale.EN]: `${Locale.EN.toUpperCase()} - ${LANGUAGE[Locale.EN]}`,
    [Locale.PT]: `${Locale.PT.toUpperCase()} - ${LANGUAGE[Locale.PT]}`,
    [Locale.UK]: `${Locale.UK.toUpperCase()} - ${LANGUAGE[Locale.UK]}`,
    [Locale.RU]: `${Locale.RU.toUpperCase()} - ${LANGUAGE[Locale.RU]}`,
};

export const APP_NAME = "TapeMeet";

export const ANDROID_APP_ID = "com.tapemeet";

export const APPS_FLYER_DEEP_LINKS = {
    DELETION: "https://app.tapemeet.com/YWLO/delete",
};
