import React from "react";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import {PageTitle} from "../common/styled";

const FacebookDeletionWrap = styled.div``;

const Description = styled.div``;

function FacebookDeletionView() {
    // TODO: realize GET request to check if FB data is deleted
    return (
        <FacebookDeletionWrap>
            <PageTitle variant={"h1"}>
                <FormattedMessage id={"facebook.deletion.title"} />
            </PageTitle>
            <Description>
                <FormattedMessage id={"facebook.deletion.description"} />
            </Description>
        </FacebookDeletionWrap>
    );
}

export default FacebookDeletionView;
