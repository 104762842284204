import React, {useContext} from "react";
import {EN_DATING_SAFETY_TIPS} from "./enHtml";
import {HtmlContainer, PageTitle} from "../common/styled";
import {Locale} from "../../../utils/types";
import {RU_DATING_SAFETY_TIPS} from "./ruHtml";
import {FormattedMessage} from "react-intl";
import {TranslationHookContext} from "../../TranslationProvider";
import {UK_DATING_SAFETY_TIPS} from "./ukHtml";

function DatingSafetyTipsView() {
    const {locale} = useContext(TranslationHookContext);

    const htmlMap = {
        [Locale.EN]: EN_DATING_SAFETY_TIPS,
        [Locale.PT]: EN_DATING_SAFETY_TIPS,
        [Locale.UK]: UK_DATING_SAFETY_TIPS,
        [Locale.RU]: RU_DATING_SAFETY_TIPS,
    };

    const html = htmlMap[locale] || EN_DATING_SAFETY_TIPS;

    return (
        <>
            <PageTitle variant={"h1"}>
                <FormattedMessage id={"datingSafetyTips"} />
            </PageTitle>
            <HtmlContainer dangerouslySetInnerHTML={{__html: html}} />
        </>
    );
}

export default DatingSafetyTipsView;
