import React from "react";
import {PageDescription, PageTitle} from "../common/styled";
import {FormattedMessage} from "react-intl";
import {COLOR} from "../../../utils/constants";
import {PageWrap} from "../../../utils/styled";

function ContactView() {
    return (
        <PageWrap>
            <PageTitle variant={"h4"}>
                <FormattedMessage id={"contactUs"} />
            </PageTitle>
            <PageDescription variant={"body1"}>
                <FormattedMessage id={"contact.description.before"} />
                &nbsp;
                <a href={"mailto:contact@tapemeet.com"} style={{color: COLOR.primary}}>
                    <FormattedMessage id={"contact.description.email"} />
                </a>
                &nbsp;
                <FormattedMessage id={"contact.description.after"} />
            </PageDescription>
        </PageWrap>
    );
}

export default ContactView;
