export const RU_COMMUNITY_GUIDELINES = `
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Добро пожаловать в сообщество. Если вы честны, добры и уважительны по отношению к другим, мы всегда будем рады видеть вас здесь. Однако если вы ведете себя иначе, к сожалению, мы будем вынуждены расстаться с вами. Наша цель состоит в том, чтобы пользователи могли свободно выражаться, пока это не задевает чувства других. Для всех действует единый стандарт поведения. Мы просим вас относиться к другим с уважением, тщательно продумывать свои действия и соблюдать правила нашего сообщества как онлайн, так и офлайн. Все верно: ваше поведение офлайн может привести к блокировке вашего аккаунта.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ниже приведен список правил нашего сообщества. Если вы нарушите какие-либо из этих правил, мы удалим вас из сообщества. Пожалуйста,&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="report" style="text-decoration:none;"><span
        style="font-size:11pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">сообщайте</span></a><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;о любых поступках, нарушающих нашу политику, и не забудьте ознакомиться с нашими&nbsp;</span><a
        href="/dating-safety-tips" style="text-decoration:none;"><span
        style="font-size:11pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Рекомендациями по безопасности</span></a><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">На TapeMeet нет места для:</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Эротическое или сексуальное содержание</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Размещайте фото, подходящие для публичного просмотра. Никакой наготы, явно выраженного сексуального контента или подробного описания ваших сексуальных предпочтений в биографии. Соблюдайте правила приличия.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Проявление агрессии</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не участвуйте и не призывайте других пользователей участвовать в оскорбительных или агрессивных действиях в отношении любых других пользователей. Мы также запрещаем отправлять без разрешения любые материалы, содержащие сексуальный контекст. Любые сообщения о домогательствах, угрозах или запугивании рассматриваются очень серьезно.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Насилие и причинение физического вреда</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы не допускаем размещения контента с элементами насилия и жестокости, а также запрещаем любые действия и материалы, пропагандирующие насилие в любом виде, включая угрозы или призывы к терроризму. Строго запрещены любые действия, которые могут причинить физический вред, принуждение и акты физического насилия.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Материалы, пропагандирующие самоубийство или членовредительство, также строго запрещены. В подобных ситуациях мы можем принять ряд мер для помощи пользователю, включая обращение в службы поддержки в кризисных ситуациях.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Недоброжелательные высказывания</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не допускается публикация контента, который пропагандирует, разжигает или оправдывает расизм, нетерпимость, ненависть или жестокое обращение по отношению к людям или группам людей по признаку национальной, этнической или религиозной принадлежности, в связи с инвалидностью, полом, возрастом, происхождением, сексуальной ориентацией или гендерной самоидентификацией.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Личная информация</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не публикуйте открыто свою или чужую личную информацию, включая номера страховых полисов, паспортов, пароли, финансовые сведения или дополнительные контактные данные, например номера телефонов, адреса электронной почты, домашние/рабочие адреса.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Спам</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не создавайте фейковых страниц. Будьте настоящими. Не используйте TapeMeet как инструмент для публикации ссылок или привлечения пользователей на другие сайты.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Реклама и пропаганда</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Реклама и пропаганда в сообществе недопустимы. Вы вправе приглашать других пользователей куда хотите, однако мы удаляем профили, цель которых &mdash; реклама мероприятия или услуг, некоммерческих и политических кампаний, конкурсов или проведение исследований.&nbsp;</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Проституция и торговля людьми</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Оказание сексуальных услуг, торговля людьми или другие сексуальные действия без обоюдного согласия сторон и пособничество в таких действиях строго запрещены и влекут блокировку аккаунта.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мошенничество</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы блокируем всех, кто пытается получить доступ к личной информации других людей в мошеннических или незаконных целях.&nbsp;</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Использование других личностей</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не выдавайте себя за других и не вводите в заблуждение других пользователей по поводу ваших связей с другими людьми или организациями.&nbsp;</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Несовершеннолетние</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Все пользователи должны быть не младше 18 лет. Поэтому мы не разрешаем публиковать фотографии несовершеннолетних лиц без сопровождения взрослых. Если вы хотите опубликовать фотографии своих детей, вы также должны присутствовать на этих фотографиях. Немедленно сообщите нам, если вы натолкнулись на профиль, в котором опубликованы фотографии несовершеннолетних лиц без взрослых, содержатся призывы к причинению вреда детям или несовершеннолетние лица представлены в сексуальном или вызывающем образе.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Нарушение авторских прав и товарные знаки</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не публикуйте не свои материалы. Если в вашем профиле есть работы, защищенные товарными знаками или авторскими правами других лиц, не демонстрируйте их без прямого разрешения правообладателя.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Незаконное использование</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не используйте TapeMeet в незаконных целях. Если что-то незаконно в жизни, это незаконно и в TapeMeet.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Один человек &mdash; один профиль</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">У аккаунта не может быть нескольких владельцев, поэтому не создавайте учетные записи на двоих с другом или второй половинкой. И, пожалуйста, не создавайте несколько аккаунтов.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Сторонние приложения</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не используйте приложения для доступа к сервисам TapeMeet или разблокирования специальных функций, созданные сторонними разработчиками.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Бездействие профиля</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Если вы в течение 2 лет не заходили в свой профиль, мы можем удалить вашу учетную запись в связи с отсутствием активности.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">В случае необходимости обратитесь в местные правоохранительные органы, а затем&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:11pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">свяжитесь с нами</span></a><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TapeMeet оставляет за собой право проводить расследования и (или) прекращать действие вашего аккаунта без возмещения каких-либо покупок, если вы злоупотребляете приложением или если, по мнению TapeMeet, ваше поведение является неприемлемым, незаконным или нарушающим Условия использования, включая действия или сообщения вне приложения в отношении пользователей, с которыми вы познакомились с помощью приложения.</span>
</p>
`;
