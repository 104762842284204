import {createTheme} from "@material-ui/core";
import {COLOR} from "./constants";

export const theme = createTheme({
    palette: {
        primary: {
            main: COLOR.primary,
        },
        secondary: {
            main: COLOR.secondary,
        },
    },
});
