export enum Route {
    HOME = "/",
    PRIVACY_POLICY = "/privacy-policy",
    CALIFORNIA_POLICY = "/california-policy",
    COOKIE_POLICY = "/cookie-policy",
    TERMS = "/terms",
    CONTACT = "/contact",
    DATING_SAFETY_TIPS = "/dating-safety-tips",
    COMMUNITY_GUIDELINES = "/community-guidelines",
    FACEBOOK_DELETION = "/facebook/deletion",
    APP = "/app",
    UNSUBSCRIBE_EMAIL_NOTIFICATION = "/notification/email/unsubscribe",
    DELETION = "/deletion",
}

export enum Locale {
    EN = "en",
    PT = "pt",
    UK = "uk",
    RU = "ru",
}

export enum LocalStorage {
    LOCALE = "locale",
}

export enum NotificationEmailCategory {
    PROMOTIONS = "promotions",
    NEW_MATCHES = "newMatches",
}

export enum Social {
    TIKTOK = "tiktok",
    INSTAGRAM = "instagram",
    FACEBOOK = "facebook",
    YOUTUBE = "youtube",
}

export enum UtmSource {
    GET_APP = "getApp",
    ADS = "ads",
}

export enum UtmMedium {
    WEB = "web",
    GOOGLE_ADS = "googleAds",
}

export enum UtmContent {
    INSTALL_APP = "installApp",
    OPEN_PLAY_MARKET = "openPlayMarket",
}
