import React, {useEffect} from "react";
import GetAppView from "../Home/GetApp";
import {LogEvent, handleLogEvent} from "../../../firebase";
import {getUrlPathname, getUrlSearch} from "../../../utils/helpers";

export enum AppStore {
    ANDROID = "Android",
    IOS = "IOS",
}

/**
 * This Component is needed in case of using Custom Deep Link implementation from the Server.
 * Currently, we've switched to AppsFlyer OneLink implementation, so, this component is needed just to handle the deep links
 * which are in the emails were sent before AppsFlyer implementation.
 * @constructor
 */
function DeepLink() {
    useEffect(() => {
        setTimeout(function () {
            // currently, we have only Android App.
            // In the future, we should have a possibility to relocate user to the suitable Mobile Store: Google or Apple.
            // Use this module to get OS: https://www.npmjs.com/package/platform
            handleLogEvent(LogEvent.RELOCATE_DEEP_LINK, {
                timestamp: new Date().toISOString(),
                appStore: AppStore.ANDROID,
            });
            window.location.href = `https://play.google.com/store/apps/details/${getUrlSearch()}`;
        }, 25);

        handleLogEvent(LogEvent.OPEN_DEEP_LINK, {
            timestamp: new Date().toISOString(),
            appStore: AppStore.ANDROID,
        });
        window.location.href = `tapemeet://${getUrlPathname()}${getUrlSearch()}`;
    }, []);
    return <GetAppView />;
}

export default DeepLink;
