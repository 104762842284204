import styled from "styled-components";
import {Typography} from "@material-ui/core";
import {Link as RrdLink} from "react-router-dom";
import {COLOR} from "../../../utils/constants";

export const Page = styled.section`
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 320px;
    max-width: 1024px;
    margin: 0 auto;
`;

export const PageTitle = styled(Typography)`
    && {
        align-self: center;
        text-align: center;
        margin: 0 auto;
        font-size: 23pt;
        font-weight: bold;
        padding-bottom: 10px;
    }
`;

export const PageDescription = styled(Typography)``;

export const HtmlContainer = styled.div`
    li {
        white-space: nowrap !important;
    }
`;

const LINK_STYLES = `  color: ${COLOR.primary};
    text-decoration: none;

    &:hover {
        color: ${COLOR.primary};
        text-decoration: underline;
    }`;
export const NavigationLinkS = styled(RrdLink)`
    ${LINK_STYLES};
    color: ${COLOR.text};
    margin: 0 10px;
`;

export const LinkS = styled("a")`
    ${LINK_STYLES};
`;
