import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {COLOR, REST_URL} from "../../../../../utils/constants";
import {PageWrap} from "../../../../../utils/styled";
import {Box, Button, ButtonProps, CheckboxProps, Link, LinkProps, Typography} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import styled from "styled-components/macro";
import NotificationCategoryView from "./Category";
import {NotificationEmailCategory, Route as ERoute} from "../../../../../utils/types";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import {handleLogEvent, LogEvent} from "../../../../../firebase";
import {getUrlParam} from "../../../../../utils/helpers";

const Page = styled(PageWrap)`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const Title = styled(Typography)``;

const Description = styled(Typography)``;

const Notifications = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 0;
`;

const ErrorWrap = styled(Box)`
    padding: 20px 0;
    color: ${COLOR.primary};
`;

const Update = styled(Button)`
    && {
        display: flex;
        align-self: center;
        margin-bottom: 30px;
    }
`;

const UnsubscribeAll = styled(Link)`
    && {
        color: ${COLOR.text};
        text-decoration-color: ${COLOR.text};
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
`;

const SuccessWrap = styled(Title)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function UnsubscribeEmailNotificationView() {
    const history = useHistory();

    const [isUnsubscribed, setIsUnsubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error>();

    const categories = Object.values(NotificationEmailCategory);

    const [selectedCategories, setSelectedCategories] = useState<NotificationEmailCategory[]>(categories);

    const getCombinedLogEvent = (logEvent: LogEvent, utmSource?: string | null) => {
        if (!utmSource) {
            return logEvent;
        }
        return _.camelCase(`${logEvent}_${utmSource}`) as LogEvent;
    };

    useEffect(() => {
        const uuid = getUrlParam("uuid");
        const utmSource = getUrlParam("utm_source");

        if (!uuid) {
            history.replace(ERoute.HOME);
        } else {
            handleLogEvent(getCombinedLogEvent(LogEvent.OPEN_UNSUBSCRIBE, utmSource), {
                timestamp: new Date().toISOString(),
                uuid,
                utmSource,
            });
        }
    }, []);

    const handleUnsubscribe = async (categories: NotificationEmailCategory[]) => {
        const uuid = getUrlParam("uuid");
        const utmSource = getUrlParam("utm_source");

        try {
            setError(undefined);
            setIsLoading(true);
            await axios.put(`${REST_URL}/notification/email/unsubscribe`, {uuid, categories});
            setIsUnsubscribed(true);

            const timestamp = new Date().toISOString();

            categories.length === 0
                ? handleLogEvent(getCombinedLogEvent(LogEvent.UNSUBSCRIBE_ALL, utmSource), {
                      timestamp,
                      uuid,
                      utmSource,
                  })
                : handleLogEvent(getCombinedLogEvent(LogEvent.UPDATE_UNSUBSCRIBE, utmSource), {
                      timestamp,
                      uuid,
                      utmSource,
                  });
        } catch (err) {
            setError(err as Error);
            setIsUnsubscribed(false);

            const timestamp = new Date().toISOString();
            handleLogEvent(getCombinedLogEvent(LogEvent.UNSUBSCRIBE_FAILED, utmSource), {
                timestamp,
                uuid,
                utmSource,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeCategory: CheckboxProps["onChange"] = (e, checked) => {
        const category = e.currentTarget.name as NotificationEmailCategory;
        setSelectedCategories(prev => {
            return checked ? _.uniq([...prev, category]) : prev.filter(c => c !== category);
        });
    };

    const handleUnsubscribeAll: LinkProps["onClick"] = async () => {
        if (!isLoading) {
            await handleUnsubscribe([]);
        }
    };

    const handleUpdate: ButtonProps["onClick"] = async () => {
        await handleUnsubscribe(selectedCategories);
    };

    if (isUnsubscribed) {
        return (
            <Page>
                <SuccessWrap variant={"h6"}>
                    <CheckCircleOutlineIcon color={"primary"} />
                    <FormattedMessage id={"notification.email.unsubscribe.success"} />
                </SuccessWrap>
            </Page>
        );
    }

    return (
        <Page>
            <Title variant={"h6"}>
                <FormattedMessage id={"notification.email.title"} />
            </Title>
            <Description variant={"subtitle1"}>
                <FormattedMessage id={"notification.email.description"} />:
            </Description>
            <Notifications>
                {categories.map(category => {
                    const checked = selectedCategories.some(c => c === category);

                    return (
                        <NotificationCategoryView
                            key={category}
                            category={category}
                            checked={checked}
                            onChange={handleChangeCategory}
                        />
                    );
                })}
            </Notifications>

            <Update variant={"contained"} onClick={handleUpdate} disabled={isLoading}>
                <FormattedMessage id={"general.update"} />
            </Update>
            <UnsubscribeAll onClick={handleUnsubscribeAll}>
                <FormattedMessage id={"notification.email.unsubscribe.all"} />
            </UnsubscribeAll>
            {error && (
                <ErrorWrap>
                    <FormattedMessage id={"notification.email.unsubscribe.error"} />
                </ErrorWrap>
            )}
        </Page>
    );
}

export default UnsubscribeEmailNotificationView;
