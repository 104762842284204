export const UK_COMMUNITY_GUIDELINES = `
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Вітаємо у спільноті. Якщо ти чесно, доброзичливо та з повагою ставишся до інших, тобі тут завжди раді. Наша мета &mdash; дати користувачам можливість вільно виражати себе, доки це не ображає інших. Для нас всі рівні. Ми просимо тебе ставитися до інших із повагою, робити обдумані вчинки і дотримуватися наших правил спільноти онлайн і офлайн. Саме так: твоя поведінка за межами мережі може призвести до блокування твого облікового запису.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Нижче наведено список правил нашого суспільства. Якщо ти порушиш будь-яке з них, тебе можуть заблокувати. Ми радимо&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="report" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">повідомляти</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;про будь-яку поведінку, що порушує наші правила, та прочитати наші&nbsp;</span><a
        href="/dating-safety-tips" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Рекомендації з безпеки при знайомствах</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TapeMeet не схвалює такі дії:</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Зображення оголеного тіла та зміст сексуального характеру</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Жодного оголеного тіла, змісту сексуального характеру та детального опису ваших сексуальних бажань у біографії. Нехай твоє спілкування буде прийнятним для суспільства.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Цькування</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не бери участь та не спонукай інших брати участь у цілеспрямованих образах чи цькуванні іншого користувача. Сюди також входить і надсилання матеріалів сексуального характеру своїм парам без їхнього прохання. Повідомлення про переслідування, погрози, залякування або утискання сприймаються дуже серйозно.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Насильство та фізична шкода</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми не будемо терпіти матеріали зі сценами насильства, жорстокості або крові чи будь-які дії або матеріали, які захищають або пропагують насильництво будь-якого виду, в тому числі тероризм. Фізичні напади, застосування сили та будь-які інші акти агресії суворо заборонені.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Матеріали, що захищають або пропагують самогубство чи завдання собі шкоди, не дозволяються. В таких випадках ми можемо вжити низку заходів, щоб допомогти користувачеві, в тому числі надати кризові ресурси.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ненависницькі висловлювання</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Жодні матеріали, що захищають, пропагують або толерують расизм, нетерпимість, ненависть або насильство проти осіб або груп, що базуються на таких факторах, як раса, національність, релігійні переконання, інвалідність, стать, вік, походження, сексуальна орієнтація або гендерна ідентичність, не дозволяються.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Конфіденційна інформація</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не надавайте публічно жодну конфіденційну інформацію, свою або інших. Сюди входять номери соціального страхування, паспорти, паролі, фінансова інформація або особиста контактна інформація, наприклад номери телефонів, електронні адреси, робочі та домашні адреси.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Спам</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не видавай себе за когось іншого. Будь реальним. Не використовуй спільноту, щоби запросити відвідувачів до зовнішніх сайтів за посиланням чи іншим способом.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Реклама та наполегливі прохання</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Розділяти свої інтереси з парою &mdash; це чудово, але якщо метою твого профілю є реклама подій чи бізнесу, неприбуткової компанії, політичної кампанії чи конкурсу або проведення дослідження, ми можемо видалити твій обліковий запис. Не використовуй TapeMeet для реклами.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Проституція та торгівля людьми</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Реклама або захист комерційних сексуальних послуг, торгівлі людьми або інших неузгоджених дій сексуального характеру суворо заборонені й призведуть до блокування твого облікового запису.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Шахрайство</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Будь-кого, хто намагається отримати конфіденційну інформацію іншого користувача для шахрайської або незаконної діяльності, може бути заблоковано. Користувачі, які надають власну фінансову інформацію з метою отримання грошей у інших користувачів, також можуть бути заблоковані.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Привласнення іншої особистості</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Будь собою! Не видавай себе за когось іншого.</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не привласнюй чужу особистість та не створюй враження про зв&apos;язок з будь-якою іншою особою. Це стосується і пародійних облікових записів.&nbsp;</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Неповнолітні</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для використання TapeMeet тобі має бути не менше 18 років. Тому ми не дозволяємо фотографії неповнолітніх без супроводу. Якщо ти хочеш опублікувати фото своїх дітей, вони мають бути на фото разом з тобою. Якщо ти бачиш профіль з неповнолітніми без супроводу або профіль пропагує шкоду неповнолітнім чи демонструє неповнолітніх в сексуальному або непристойному вигляді, негайно повідомте про це.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Порушення авторських прав чи товарного знаку</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо щось тобі не належить, не публікуй це. Якщо в твоєму профілі є роботи, захищені авторськими правами чи товарним знаком інших, не демонструй їх, якщо у тебе немає на це права.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Незаконне використання</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Не використовуй TapeMeet для незаконної діяльності. Якщо щось є незаконним в реальному житті, воно є незаконним і на TapeMeet.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Одна особа, один обліковий запис</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Облікові записи TapeMeet не можуть мати кількох власників, тому не треба створювати спільний обліковий запис з кимось із друзів чи близьких. Крім того, не слід вести кілька облікових записів.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Сторонні додатки</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Використання додатків, створених не TapeMeet, що пропонують наші послуги або розблокують спеціальні функції не дозволяються.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Бездіяльність облікового запису</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Якщо ти не входив до облікового запису протягом двох років, ми можемо видалити твій обліковий запис за бездіяльність.</span>
</p>
<p><br></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">За необхідності звернись до місцевих правоохоронних органів, а потім зв&apos;яжись із нами&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">тут</span></a>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TapeMeet залишає за собою право проводити розслідування та/або припинити дію твого облікового запису без відшкодування будь-яких покупок, якщо ти зловживаєш додатком або поводишся на думку TapeMeet неприйнятно, беззаконно чи невідповідно умовам використання. Сюди також відносяться дії чи спілкування поза межами TapeMeet відношенні до користувачів, знайомство з якими відбулося через TapeMeet.</span>
</p>

`;
