import React from "react";
import styled from "styled-components/macro";
import {APP_NAME, COLOR, OPACITY} from "../../../../../utils/constants";
import {Link} from "react-router-dom";
import {ReactComponent as LogoSvg} from "../../../../../images/logo.svg";

const LogoWrap = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOR.text};
    text-decoration: none;

    &:active {
        opacity: ${OPACITY};
    }
`;

const Title = styled.span`
    padding-left: 5px;
`;

function LogoView() {
    return (
        <LogoWrap to={"/"}>
            <LogoSvg width={20} height={20} />
            <Title>{APP_NAME}</Title>
        </LogoWrap>
    );
}

export default LogoView;
