export const EN_PRIVACY_POLICY = `
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Welcome to TapeMeet&rsquo;s Privacy Policy. Thank you for taking the time to read it.&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information.&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">EFFECTIVE DATE: August 1, 2021</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For California Consumers</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Please see our</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="/california-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">California Privacy Statement</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to learn about California privacy rights.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Who We Are</span>
</h3>
<p dir="ltr"
   style="line-height:1.7148;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 15pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">An individual Serhii Lyzun &nbsp;acts as the operator of personal data collected and processed within the TapeMeet application. Indicated in the text of this Policy as &quot;we&quot; and &quot;us&quot;.</span>
</p>
<p dir="ltr"
   style="line-height:1.542857142857143;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:-16pt 0pt -1pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You can contact the TapeMeet Data Protection Officer by writing to&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:15pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. Where This Privacy Policy Applies</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Privacy Policy applies to the TapeMeet&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#202124;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">app.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. Information We Collect</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">It goes without saying, we can&rsquo;t help you develop meaningful connections without some information about you, such as basic profile details and the types of people you&rsquo;d like to meet. We also collect information generated as you use our services, for example access logs, as well as information from third parties, like when you access our services through a social media account. If you want additional info, we go into more detail below.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Information you give us</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You choose to give us certain information when using TapeMeet. This includes:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the app to work, such as your gender and date of birth.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you complete your profile, you can share with us additional information, such as details on your personality, lifestyle, interests and other details about you, as well as content such as photos and videos. To add certain content, like pictures or videos, you may allow us to access your camera or photo album. Some of the information you choose to provide us may be considered &ldquo;special&rdquo; or &ldquo;sensitive&rdquo; in certain jurisdictions, for example your racial or ethnic origins, sexual orientation and religious beliefs. By choosing to provide this information, you consent to our processing of that information.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you subscribe to a paid service (rather than through a platform such as iOS or Android), you provide us or our payment service provider with information, such as your debit or credit card number or other financial information.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you participate in surveys or focus groups, you give us your insights into our products and services, responses to our questions and testimonials.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you contact our customer care team, we collect the information you give us during the interaction. Sometimes, we monitor or record these interactions for training purposes and to ensure a high quality of service.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you ask us to communicate with or otherwise process information of other people (for example, if you ask us to send an email on your behalf to one of your friends), we collect the information about others that you give us in order to complete your request.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Of course, we also process your chats with other users as well as the content you publish, as part of the operation of the app.</span>
        </p>
    </li>
</ul>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Information we receive from others</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In addition to the information you provide us directly, we receive information about you from others, including:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other Users.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Other users may provide information about you as they use our app. For instance, we may collect information about you from other users if they contact us about you.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Social Media.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;You may be able to use your social media login (such as Facebook Login) to create and log into your TapeMeet account. This saves you from having to remember yet another user name and password and allows you to share some information from your social media account with us.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other Partners.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;We may receive info about you from our partners, for instance where TapeMeet ads are published on a partner&rsquo;s websites and platforms (in which case they may pass along details on a campaign&rsquo;s success).</span>
        </p>
    </li>
</ul>
<h4 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 2pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Information collected when you use our services</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you use our app, we collect information about which features you&rsquo;ve used, how you&rsquo;ve used them and the devices you use to access our app. See below for more details:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usage Information.&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;We collect information about your activity on our app, for instance how you use them (e.g., date and time you logged in, features you&rsquo;ve been using, searches, clicks and pages which have been shown to you, referring web page address, advertising that you click on) and how you interact with other users (e.g., users you connect and interact with, time and date of your exchanges, number of messages you send and receive).</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Device information.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;We collect information from and about the device(s) you use to access our app, including:</span>
        </p>
        <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li aria-level="2" dir="ltr"
                style="list-style-type:circle;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                <p dir="ltr"
                   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">hardware and software information such as IP address, device ID and type, device-specific and apps settings and characteristics, app crashes, advertising IDs (such as Google&rsquo;s AAID and Apple&apos;s IDFA, both of which are randomly generated numbers that you can reset by going into your device&rsquo; settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address);</span>
                </p>
            </li>
            <li aria-level="2" dir="ltr"
                style="list-style-type:circle;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                <p dir="ltr"
                   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">information on your wireless and mobile network connection, like your service provider and signal strength;</span>
                </p>
            </li>
            <li aria-level="2" dir="ltr"
                style="list-style-type:circle;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                <p dir="ltr"
                   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">information on device sensors such as accelerometers, gyroscopes and compasses.</span>
                </p>
            </li>
        </ul>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other information with your consent</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;If you give us permission, we can collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you&rsquo;re using, including GPS, Bluetooth or Wi-Fi connections. The collection of your geolocation may occur in the background even when you aren&rsquo;t using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your geolocation, we will not collect it. Similarly, if you consent, we may collect your photos and videos (for instance, if you want to publish a photo, video or streaming on the app).</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. Cookies and Other Similar Data Collection Technologies</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to recognize you and/or your device(s). You may read our</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="/cookie-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookie Policy</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;for more information on why we use them (such as authenticating you, remembering your preferences and settings, analyzing site traffic and trends, delivering and measuring the effectiveness of advertising campaigns, allowing you to use social features) and how you can better control their use, through your settings.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;" id="section-5">5. How We Use Information</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The main reason we use your information is to deliver and improve our service. Additionally, we use your info to help keep you safe and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:2pt;padding:2pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To administer your account and provide our services to you</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Create and manage your account</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Provide you with customer support and respond to your requests</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Complete your transactions</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Communicate with you about our services, including order management and billing</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To help you connect with other users</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Analyze your profile, activity on the app, and preferences to recommend meaningful connections to you and recommend you to others</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Show users&rsquo; profiles to one another</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To ensure a consistent experience across your devices</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Link the various devices you use so that you can enjoy a consistent experience of our services on all of them. We do this by linking devices and browser data, such as when you log into your account on different devices or by using partial or full IP address, browser version and similar data about your devices to help identify and link them.</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To provide new TapeMeet services to you</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Register you and display your profile on new TapeMeet features and apps</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Administer your account on these new features and apps</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To serve you relevant offers and ads</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Administer sweepstakes, contests, discounts or other offers</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Develop, display and track content and advertising tailored to your interests on our services and other sites</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Communicate with you by email, phone, social media or mobile device about products or services that we think may interest you</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To improve our services and develop new ones</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Administer focus groups and surveys</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conduct research and analysis of users&rsquo; behavior to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users&rsquo; behavior)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Develop new features and services (for example, we may decide to build a new interests-based feature further to requests received from users).</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To prevent, detect and fight fraud or other illegal or unauthorized activities</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Address ongoing or alleged misbehavior on and off-platform</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Perform data analysis to better understand and design countermeasures against these activities</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Retain data related to fraudulent activities to prevent against recurrences</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To ensure legal compliance</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Comply with legal requirements</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Assist law enforcement</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Enforce or exercise our rights, for example our Terms</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To process your information as described above, we rely on the following legal bases:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Provide our service to you.&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using TapeMeet to build meaningful connections, we use your information to maintain your account and your profile, to make it viewable to other users and recommend other users to you.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Legitimate interests.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;We may use your information where we have legitimate interests to do so. For instance, we analyze users&rsquo; behavior on the app to continuously improve our offerings, we suggest offers we think might interest you, and we process information for administrative, fraud detection and other legal purposes.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Consent.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span id="section-6" style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6. How We Share Information</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:6pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Since our goal is to help you make meaningful connections, the main sharing of users&rsquo; information is, of course, with other users. We also share some users&rsquo; information with service providers and partners who assist us in operating the services and in some cases, legal authorities. Read on for more details about how your information is shared with others.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">With other users</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You share information with other users when you voluntarily disclose information on the app (including your public profile). Please be careful with your information and make sure that the content you share is stuff that you&rsquo;re comfortable being publically viewable since neither you nor we can control what others do with your information once you share it.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you choose to limit the audience for all or part of your profile or for certain content or information about you, then it will be visible according to your settings.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">With our service providers and partners</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We use third parties to help us operate and improve our app. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may also share information with partners who distribute and assist us in advertising TapeMeet. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We follow a strict vetting process prior to engaging any service provider or working with any partner. All of our service providers and partners must agree to strict confidentiality obligations.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For corporate transactions</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When required by law</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may disclose your information if reasonably necessary:&nbsp;</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2) to assist in the prevention or detection of crime (subject in each case to applicable law);&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3) to protect the safety of any person.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To enforce legal rights</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may also share information:&nbsp;</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1) if disclosure would mitigate our liability in an actual or threatened lawsuit;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3) to enforce our agreements with you;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">With your consent or at your request</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioral data, geolocation in de-identified form), as well as personal information in hashed, non-human readable form, under any of the above circumstances. We may also share this information with third parties (notably advertisers) to develop and deliver targeted advertising on our app and on websites or applications of third parties, and to analyze and report on advertising you see. We may combine this information with additional non-personal information or personal information in hashed, non-human readable form collected from other sources. More information on our use of cookies and similar technologies can be found in our&nbsp;</span><a
                href="/cookie-policy" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookie Policy</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Cross-Border Data Transfers</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sharing of information laid out in</span><span
        style="font-size:13pt;font-family:Arial;color:#fd267d;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="#section-6"
        style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Section 6</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;sometimes involves cross-border data transfers, for instance to the United States of America and other jurisdictions. As an example, where the service allows for users to be located in the European Economic Area (&ldquo;EEA&rdquo;), their personal information is transferred to countries outside of the EEA. We use standard contract clauses approved by the European Commission or other suitable safeguard to permit data transfers from the EEA to other countries. Standard contractual clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your data</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Your Rights</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:6pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We want you to be in control of your information, so we have provided you with the following tools:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Access / Update tools in the service.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Tools and account settings that help you to access, rectify or delete information that you provided to us and that&rsquo;s associated with your account directly within the service. If you have any questions on those tools and settings, please contact our customer care team for help&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">here</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Device permissions.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Mobile platforms have permission systems for specific types of device data and notifications, such as phone book and location services as well as push notifications. You can change your settings on your device to either consent or oppose the collection of the corresponding information or the display of the corresponding notifications. Of course, if you do that, certain services may lose full functionality.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Deletion.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;You can delete your account by using the corresponding functionality directly on the service.</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We want you to be aware of your privacy rights. Here are a few key points to remember:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Reviewing your information.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Applicable privacy laws may give you the right to review the personal information we keep about you (depending on the jurisdiction, this may be called right of access, right of portability or variations of those terms). You can request a copy of your personal information by putting in such a request&nbsp;</span><a
                href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">here</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Updating your information.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;If you believe that the information we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion or object to its processing, please contact us&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">here</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer the above requests.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on trade secrets or intellectual property or the privacy of another user. If you wish to receive information relating to another user, such as a copy of any messages you received from him or her through our service, the other user will have to contact our Privacy Officer to provide their written consent before the information is released.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Also, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Uninstall.&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You can stop all information collection by an app by uninstalling it using the standard uninstall process for your device. If you uninstall the app from your mobile device, the unique identifier associated with your device will continue to be stored. If you re-install the application on the same mobile device, we will be able to re-associate this identifier to your previous transactions and activities.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accountability.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;In certain countries, including in the European Union, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how we process your personal information. The data protection authority you can lodge a complaint with notably may be that of your habitual residence, where you work or where we are established.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. Residents of California</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you are a California resident, you can request a notice disclosing the categories of personal information about you that we have shared with third parties for their direct marketing purposes during the preceding calendar year. To request this notice, please submit your request&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">here</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">. Please allow 30 days for a response. For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer such a request.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10. How We Protect Your Information</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">here</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11. How Long We Retain Your Information</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We keep your personal information only as long as we need it for legitimate business purposes (as laid out in&nbsp;</span><a
        href="#section-5"
        style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;" >Section 5</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">) and as permitted by applicable law. To protect the safety and security of our users on and off the app, we implement a safety retention window of three months following account deletion. During this period, account information will be retained although the account will of course not be visible on the TapeMeet anymore.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In practice, we delete or anonymize your information upon deletion of your account (following the safety retention window) or after two years of continuous inactivity, unless:</span>
</p>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We must keep it to comply with applicable law (for instance, some &ldquo;traffic data&rdquo; is kept for one year to comply with statutory data retention obligations);</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We must keep it to evidence our compliance with applicable law (for instance, records of consents to our Terms, Privacy Policy and other similar consents are kept for five years);</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">There is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The information must be kept for our legitimate business interests, such as fraud prevention and enhancing users&apos; safety and security. For example, information may need to be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account.</span>
        </p>
    </li>
</ol>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Keep in mind that even though our systems are designed to carry out data deletion processes according to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12. Children&apos;s Privacy</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our services are restricted to users who are 18 years of age or older. We do not permit users under the age of 18 on our platform and we do not knowingly collect personal information from anyone under the age of 18. If you suspect that a user is under the age of 18, please use the reporting mechanism available.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13. Privacy Policy Changes</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Because we&rsquo;re always looking for new and innovative ways to help you build meaningful connections, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">14. How to Contact Us</span>
</h3>
<p><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you have questions about this Privacy Policy, here&rsquo;s how you can reach us:&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000910;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
            `;
