// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent, CustomEventName, AnalyticsCallOptions} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import platform from "platform";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BACKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export enum LogEvent {
    OPEN_UNSUBSCRIBE = "openUnsubscribe",
    UPDATE_UNSUBSCRIBE = "updateUnsubscribe",
    UNSUBSCRIBE_ALL = "unsubscribeAll",
    UNSUBSCRIBE_FAILED = "unsubscribeFailed",

    OPEN_DEEP_LINK = "openDeepLink",
    RELOCATE_DEEP_LINK = "relocateDeepLink",

    CLICK_GET_APP = "clickGetApp",
    CLICK_SOCIAL = "clickSocial",
}

type HandleLogEvent<T extends string> = (
    eventName: CustomEventName<T>,
    eventParams?: {[key: string]: any},
    options?: AnalyticsCallOptions
) => void;

export const handleLogEvent: HandleLogEvent<LogEvent> = (eventName, eventParams, options) => {
    logEvent(analytics, eventName, {...eventParams, platform: platform.description}, options);
};
