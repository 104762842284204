import React, {FC, ReactPropTypes, useContext} from "react";
import styled from "styled-components/macro";
import GooglePlayBadgeEn from "../../../../images/google-play-badge-en.png";
import GooglePlayBadgePt from "../../../../images/google-play-badge-pt.png";
import GooglePlayBadgeUk from "../../../../images/google-play-badge-uk.png";
import GooglePlayBadgeRu from "../../../../images/google-play-badge-ru.png";
import {TranslationHookContext} from "../../../TranslationProvider";
import {Locale, UtmContent, UtmMedium, UtmSource} from "../../../../utils/types";
import {IntlShape, useIntl} from "react-intl";
import {ANDROID_APP_ID, BREAKPOINT} from "../../../../utils/constants";
import {LogEvent, handleLogEvent} from "../../../../firebase";
import {AppStore} from "../../DeepLink";

const Wrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    @media all and (max-width: ${BREAKPOINT.mobile}px) {
        flex-direction: column;
    }
`;

const Title = styled.span`
    font-weight: bold;
    font-size: 30px;
    padding-right: 20px;
    text-align: center;

    @media all and (max-width: ${BREAKPOINT.mobile}px) {
        padding-bottom: 10px;
        padding-right: 0;
    }
`;

const Link = styled.a`
    > img {
        width: 200px;
    }
`;

function GetAppView({showTitle = true}: {showTitle?: boolean}) {
    const {locale} = useContext(TranslationHookContext);
    const intl = useIntl();

    const image = getImageByLocale(locale, intl);

    const onClick = () => {
        handleLogEvent(LogEvent.CLICK_GET_APP, {
            timestamp: new Date().toISOString(),
            appStore: AppStore.ANDROID,
        });
    };

    const href = `https://play.google.com/store/apps/details/?id=${ANDROID_APP_ID}&referrer=utm_source=${UtmSource.GET_APP}&utm_medium=${UtmMedium.WEB}&utm_content=${UtmContent.OPEN_PLAY_MARKET}`;

    return (
        <Wrap>
            {showTitle && <Title>{intl.formatMessage({id: "get.app.title"})}:</Title>}
            <Link href={href} target={"_blank"} onClick={onClick}>
                {image}
            </Link>
        </Wrap>
    );
}

export default GetAppView;

const getImageByLocale = (locale: Locale, intl: IntlShape) => {
    const alt = intl.formatMessage({id: "get.app.alt"});

    switch (locale) {
        case Locale.EN:
            return <img src={GooglePlayBadgeEn} alt={alt} />;
        case Locale.PT:
            return <img src={GooglePlayBadgePt} alt={alt} />;
        case Locale.UK:
            return <img src={GooglePlayBadgeUk} alt={alt} />;
        case Locale.RU:
            return <img src={GooglePlayBadgeRu} alt={alt} />;
        default:
            return <img src={GooglePlayBadgeEn} alt={alt} />;
    }
};
