export const EN_COOKIE_POLICY = `
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Introduction</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Cookie Policy explains what cookies are, what types of cookies are placed on your device when you visit our website and how we use them.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Cookie Policy does not address how we deal with your personal information generally. To learn more about how we process your personal information, please see our&nbsp;</span><a
        href="/privacy-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Privacy Policy</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">What are cookies?</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookies are small text files that are sent to or accessed from your web browser or your device&rsquo;s memory. A cookie typically contains the name of the domain (internet location) from which the cookie originated, the &ldquo;lifetime&rdquo; of the cookie (i.e., when it expires) and a randomly generated unique number or similar identifier. A cookie also may contain information about your device, such as user settings, browsing history and activities conducted while using our services.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Are there different types of cookies?</span>
</h3>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">First-party and third-party cookies</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">There are first-party cookies and third-party cookies. First-party cookies are placed on your device directly by us. For example, we use first-party cookies to adapt our website to your browser&rsquo;s language preferences and to better understand your use of our website. Third-party cookies are placed on your device by our partners and service providers. For example, we use third-party cookies to measure user numbers on our website or to enable you to share content with others across social media platforms.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Session and persistent cookies</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">There are session cookies and persistent cookies. Session cookies only last until you close your browser. We use session cookies for a variety of reasons, including to learn more about your use of our website during one single browser session and to help you to use our website more efficiently. Persistent cookies have a longer lifespan and aren&apos;t automatically deleted when you close your browser. These types of cookies are primarily used to help you quickly sign-in to our website again and for analytical purposes.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">What about other tracking technologies, like web beacons?</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other technologies such as web beacons (also calls pixel tags or clear gifs), tracking URLs or software development kits (SDKs) are used for similar purposes. Web beacons are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our service or opened an e-mail that we have sent them. Tracking URLs are custom generated links that help us understand where the traffic to our webpages comes from. SDKs are small pieces of code included in apps, which function like cookies and web beacons.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For simplicity, we also refer to these technologies as &ldquo;cookies&rdquo; in this Cookie Policy.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">What do we use cookies for?</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Like most providers of online services, we use cookies to provide, secure and improve our services, including by remembering your preferences, recognizing you when you visit our website and personalizing and tailoring ads to your interests. To accomplish these purposes, we also may link information from cookies with other personal information we hold about you.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you visit our website, some or all of the following types of cookies may be set on your device.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Essential website cookies.&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Analytics cookies.&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These cookies help us understand how our website is being used, how effective marketing campaigns are, and help us customize and improve our websites for you.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Advertising cookies.&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, selecting advertisements that are based on your interests and measuring the number of ads displayed and their performance, such as how many people clicked on a given ad.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Social networking cookies.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;These cookies are used to enable you to share pages and content that you find interesting on our website through third-party social networking and other websites. These cookies may also be used for advertising purposes too.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How can you control cookies?</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">There are several cookie management options available to you. Please note that changes you make to your cookie preferences may make browsing our website a less satisfying experience. In some cases, you may even find yourself unable to use all or part of our site.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Browser and devices controls</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Some web browsers provide settings that allow you to control or reject cookies or to alert you when a cookie is placed on your computer. The procedure for managing cookies is slightly different for each internet browser. You can check the specific steps in your particular browser help menu.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You also may be able to reset device identifiers by activating the appropriate setting on your mobile device. The procedure for managing device identifiers is slightly different for each device. You can check the specific steps in the help or settings menu of your particular device.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Interest-based advertising tools</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You can opt out of seeing online interest-based advertising from participating companies through the</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="http://optout.aboutads.info/?c=2#!/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Digital Advertising Alliance</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000910;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">the</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="http://www.youronlinechoices.eu/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Interactive Digital Advertising Alliance</span></a><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">or</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="http://youradchoices.com/appchoices" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Appchoices</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(apps only).</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Opting out does not mean you will not see advertising - it means you won&rsquo;t see personalized advertising from the companies that participate in the opt-out programs. Also, if you delete cookies on your device after you opted out, you will need to opt-out again.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Social Cookies</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To allow you to share content on social media, some features of this website use social media plug-ins. Depending on your social media account settings, we automatically receive information from the social media platform when you use the corresponding button on our website.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To learn more about social media cookies, we suggest you refer to your social media platform&rsquo;s cookie policy and privacy policy.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Adobe Flash Player&trade; Flash cookies</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Adobe Flash Player&trade; is an application for viewing and interacting with dynamic content using the Flash platform. Flash (and similar applications) use a technology akin to cookies to memorize parameters, preferences and uses of this content. However, Adobe Flash Player manages this information and your choices via an interface separate from that supplied by your browser.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If your terminal is likely to display content developed using the Flash platform, we suggest you access your Flash cookie management tools directly via</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="https://www.adobe.com/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://www.adobe.com</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google&trade; Cookies</span>
</h3>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Stuff Google Wants to Make Sure You Know about Google&rsquo;s Data Collection Technology</span>
</h4>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:10pt 0pt 2pt 0pt;">
    <span style="font-size:13.999999999999998pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google&trade; Maps API Cookies</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Some features of our website and some TapeMeet services rely on the use of Google&trade; Maps API Cookies. Such cookies will be stored on your device.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When browsing this website and using the services relying on Google&trade; Maps API cookies, you consent to the storage, collection of such cookies on your device and to the access, usage and sharing by Google of the data collected thereby.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google&trade; manages the information and your choices pertaining to Google&trade; Maps API Cookies via an interface separate from that supplied by your browser. For more information, please see&nbsp;</span><a
        href="https://www.google.com/policies/technologies/cookies/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://www.google.com/policies/technologies/cookies/</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google Analytics</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:8pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We use Google Analytics, which is a Google service that uses cookies and other data collection technologies to collect information about your use of the website and services in order to report website trends.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You can opt out of Google Analytics by visiting</span><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="http://www.google.com/settings/ads" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">www.google.com/settings/ads</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">or by downloading the Google Analytics opt-out browser add-on at&nbsp;</span><a
        href="https://tools.google.com/dlpage/gaoptout" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://tools.google.com/dlpage/gaoptout</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#404040;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How to contact us?</span>
</h3>
<p><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you have questions about this Cookie Policy, here&rsquo;s how you can reach us:&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>            `;
