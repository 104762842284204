import React from "react";
import styled from "styled-components";
import {BREAKPOINT} from "../../../../../utils/constants";
import {Route} from "../../../../../utils/types";
import {FormattedMessage} from "react-intl";
import {useMediaQuery} from "@material-ui/core";
import MobileNavView from "./Mobile";
import {NavigationLinkS} from "../../styled";

const NavWrap = styled.div``;

function NavView() {
    const isMobile = useMediaQuery(`(max-width:${BREAKPOINT.mobile}px)`);

    return (
        <NavWrap>
            {isMobile ? (
                <MobileNavView />
            ) : (
                <>
                    <NavigationLinkS to={Route.PRIVACY_POLICY}>
                        <FormattedMessage id={"privacy"} />
                    </NavigationLinkS>
                    <NavigationLinkS to={Route.TERMS}>
                        <FormattedMessage id={"terms"} />
                    </NavigationLinkS>
                    <NavigationLinkS to={Route.CONTACT}>
                        <FormattedMessage id={"contact"} />
                    </NavigationLinkS>
                </>
            )}
        </NavWrap>
    );
}

export default NavView;
