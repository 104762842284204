import React from "react";
import {Switch, Route} from "react-router-dom";
import PrivacyPolicyView from "../components/Policy/Privacy";
import TermsView from "../components/Terms";
import HomeView from "../components/Home";
import ContactView from "../components/Contact";
import {Route as ERoute} from "../../utils/types";
import CaliforniaPolicyView from "../components/Policy/California";
import CookiePrivacyView from "../components/Policy/Cookie";
import DatingSafetyTipsView from "../components/DatingSafetyTips";
import CommunityGuidelinesView from "../components/CommunityGuidelines";
import FacebookDeletionView from "../components/FacebookDeletion";
import DeepLink from "../components/DeepLink";
import UnsubscribeEmailNotificationView from "../components/Notification/Email/Unsubscribe";
import Deletion from "../components/Deletion/indtex";
import {Page} from "../components/common/styled";

function Routes() {
    return (
        <Switch>
            <Route path={ERoute.HOME} exact>
                <HomeView />
            </Route>
            <Route path={ERoute.APP}>
                <Page>
                    <DeepLink />
                </Page>
            </Route>
            <Route path={ERoute.PRIVACY_POLICY}>
                <Page>
                    <PrivacyPolicyView />
                </Page>
            </Route>
            <Route path={ERoute.CALIFORNIA_POLICY}>
                <Page>
                    <CaliforniaPolicyView />
                </Page>
            </Route>
            <Route path={ERoute.COOKIE_POLICY}>
                <Page>
                    <CookiePrivacyView />
                </Page>
            </Route>
            <Route path={ERoute.TERMS}>
                <Page>
                    <TermsView />
                </Page>
            </Route>
            <Route path={ERoute.DATING_SAFETY_TIPS}>
                <Page>
                    <DatingSafetyTipsView />
                </Page>
            </Route>
            <Route path={ERoute.CONTACT}>
                <Page>
                    <ContactView />
                </Page>
            </Route>
            <Route path={ERoute.COMMUNITY_GUIDELINES}>
                <Page>
                    <CommunityGuidelinesView />
                </Page>
            </Route>
            <Route path={ERoute.FACEBOOK_DELETION}>
                <Page>
                    <FacebookDeletionView />
                </Page>
            </Route>
            <Route path={ERoute.UNSUBSCRIBE_EMAIL_NOTIFICATION}>
                <Page>
                    <UnsubscribeEmailNotificationView />
                </Page>
            </Route>
            <Route path={ERoute.DELETION}>
                <Page>
                    <Deletion />
                </Page>
            </Route>
        </Switch>
    );
}

export default Routes;
