import React from "react";
import {Box} from "@material-ui/core";
import styled from "styled-components/macro";
import {PageWrap} from "../../../utils/styled";
import {PageDescription, PageTitle, LinkS, NavigationLinkS} from "../common/styled";
import DeletionLoginImg from "../../../images/deletion/deletion-login.jpg";
import DeletionSettingsImg from "../../../images/deletion/deletion-settings.jpg";
import DeletionDeleteImg from "../../../images/deletion/deletion-delete.jpg";
import {Route} from "../../../utils/types";
import {FormattedMessage} from "react-intl";
import {APPS_FLYER_DEEP_LINKS, COLOR} from "../../../utils/constants";

const List = styled(Box)``;

const Item = styled(Box)`
    text-align: left;
`;

const ImgWrap = styled(Box)`
    img {
        max-width: 200px;
    }
`;

const NavLink = styled(NavigationLinkS)`
    margin: 0;
    color: ${COLOR.primary};
`;
const Deletion = () => {
    return (
        <PageWrap>
            <PageTitle>
                <FormattedMessage id={"deletion.title"} />
            </PageTitle>
            <PageDescription>
                <FormattedMessage id={"deletion.description"} />:
            </PageDescription>
            <List component={"ul"}>
                <Item component={"li"}>
                    <FormattedMessage
                        id={"deletion.description.openApp"}
                        values={{
                            here: (
                                <LinkS href={APPS_FLYER_DEEP_LINKS.DELETION} target={"_blank"}>
                                    <FormattedMessage id={"general.here"} />
                                </LinkS>
                            ),
                        }}
                    />
                </Item>
                <Item component={"li"}>
                    <FormattedMessage id={"deletion.description.login"} />
                </Item>
                <ImgWrap>
                    <img src={DeletionLoginImg} alt="login" />
                </ImgWrap>
                <Item component={"li"}>
                    <FormattedMessage id={"deletion.description.settings"} />
                </Item>
                <ImgWrap>
                    <img src={DeletionSettingsImg} alt="settings" />
                </ImgWrap>
                <Item component={"li"}>
                    <FormattedMessage id={"deletion.description.delete"} />
                </Item>
                <ImgWrap>
                    <img src={DeletionDeleteImg} alt="delete" />
                </ImgWrap>
                <Item component={"li"}>
                    <FormattedMessage id={"deletion.description.complete"} />
                </Item>
                <Item component={"li"}>
                    <FormattedMessage
                        id={"deletion.description.privacy"}
                        values={{
                            privacy: (
                                <NavLink to={Route.PRIVACY_POLICY}>
                                    <FormattedMessage id={"privacy"} />
                                </NavLink>
                            ),
                        }}
                    />
                </Item>
            </List>
        </PageWrap>
    );
};

export default Deletion;
