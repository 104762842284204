import React from "react";
import {CALIFORNIA_POLICY} from "./html";
import {HtmlContainer, PageTitle} from "../../common/styled";
import {FormattedMessage} from "react-intl";

function CaliforniaPolicyView() {
    return (
        <>
            <PageTitle variant={"h1"}>
                <FormattedMessage id={"californiaPolicy"} />
            </PageTitle>
            <HtmlContainer dangerouslySetInnerHTML={{__html: CALIFORNIA_POLICY}} />
        </>
    );
}

export default CaliforniaPolicyView;
