import React, {useContext} from "react";
import {EN_COOKIE_POLICY} from "./enHtml";
import {HtmlContainer, PageTitle} from "../../common/styled";
import {Locale} from "../../../../utils/types";
import {RU_COOKIE_POLICY} from "./ruHtml";
import {FormattedMessage} from "react-intl";
import {TranslationHookContext} from "../../../TranslationProvider";
import {UK_COOKIE_POLICY} from "./ukHtml";

function CookiePrivacyView() {
    const {locale} = useContext(TranslationHookContext);

    const htmlMap = {
        [Locale.EN]: EN_COOKIE_POLICY,
        [Locale.PT]: EN_COOKIE_POLICY,
        [Locale.UK]: UK_COOKIE_POLICY,
        [Locale.RU]: RU_COOKIE_POLICY,
    };

    const html = htmlMap[locale] || EN_COOKIE_POLICY;

    return (
        <>
            <PageTitle variant={"h1"}>
                <FormattedMessage id={"cookiePolicy"} />
            </PageTitle>
            <HtmlContainer dangerouslySetInnerHTML={{__html: html}} />
        </>
    );
}

export default CookiePrivacyView;
