import React, {useContext, useEffect, useState} from "react";
import {Page, PageTitle} from "../common/styled";
import {FormattedMessage} from "react-intl";
import styled from "styled-components/macro";
import GetAppView from "./GetApp";
import {PageWrap} from "../../../utils/styled";
import {TranslationHookContext} from "../../TranslationProvider";
import {Locale} from "../../../utils/types";
import homeBackground from "../../../images/home_background.jpg";
import {COLOR} from "../../../utils/constants";
import {QrCodeView} from "../common/QrCode"; // Import the image
const Description = styled.span`
    line-height: 30px;
`;

const DescriptionWrap = styled(Page)`
    padding-top: 50px;
`;

const Background = styled.div`
    background-image: url(${homeBackground});
    background-size: cover; /* Optional: cover the entire container */
    background-position: center; /* Optional: center the background image */
    background-repeat: no-repeat; /* Optional: prevent the image from repeating */
    min-width: 100vw;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const PageSubTitle = styled.div`
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
`;

const GetAppWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > * {
        color: ${COLOR.white};
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }
`;

function HomeView() {
    const {locale} = useContext(TranslationHookContext);
    const [htmlContent, setHtmlContent] = useState<string>("");

    const getHtmlContent = async () => {
        try {
            return (await import(`./home_${locale}.html`)).default;
        } catch {
            return (await import(`./home_${Locale.EN}.html`)).default ?? "";
        }
    };

    useEffect(() => {
        (async () => {
            setHtmlContent(await getHtmlContent());
        })();
    }, [locale]);

    return (
        <div>
            <Background>
                <PageTitle variant={"h1"}>
                    <PageSubTitle>
                        <FormattedMessage id={"real.video"} />
                    </PageSubTitle>
                    <PageSubTitle>=</PageSubTitle>
                    <PageSubTitle>
                        <FormattedMessage id={"real.people"} />
                    </PageSubTitle>
                    <PageSubTitle>=</PageSubTitle>
                    <PageSubTitle>
                        <FormattedMessage id={"real.dating"} />
                    </PageSubTitle>
                    <PageSubTitle>&#x2764;&#xfe0f;</PageSubTitle>
                </PageTitle>
                <GetAppWrap>
                    <QrCodeView />
                    <GetAppView showTitle={false} />
                </GetAppWrap>
            </Background>

            <DescriptionWrap>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: htmlContent,
                    }}
                />
            </DescriptionWrap>
        </div>
    );
}

export default HomeView;
