import {Locale} from "./types";
import TikTokIcon from "../images/tiktok.svg";
import InstagramIcon from "../images/instagram.svg";
import FacebookIcon from "../images/facebook.svg";
import YouTubeIcon from "../images/youtube.svg";

type Keys =
    | "tiktok"
    | "instagram"
    | "facebook"
    | "youtube"
    | "tiktokIcon"
    | "instagramIcon"
    | "facebookIcon"
    | "youtubeIcon";

export const getSocialUrls = ({locale}: {locale: Locale}): Record<Keys, string> => {
    return {
        tiktok: locale === Locale.PT ? "https://www.tiktok.com/@tapemeet.brasil" : "https://www.tiktok.com/@tapemeet",
        instagram:
            locale === Locale.PT
                ? "https://www.instagram.com/tapemeet.brasil"
                : "https://www.instagram.com/tapemeet.app",
        facebook: "https://www.facebook.com/tapemeet",
        youtube: "https://www.youtube.com/@tapemeet",
        tiktokIcon: TikTokIcon,
        instagramIcon: InstagramIcon,
        facebookIcon: FacebookIcon,
        youtubeIcon: YouTubeIcon,
    };
};

export const getUrlSearch = (): string => {
    const url = new URL(decodeURIComponent(window.location.href));

    return url.search;
};

export const getUrlPathname = (): string => {
    const url = new URL(decodeURIComponent(window.location.href));

    return url.pathname;
};
export const getUrlParam = (key: string) => {
    const url = new URL(decodeURIComponent(window.location.href));
    return url.searchParams.get(key);
};
