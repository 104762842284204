import React, {useEffect, useState} from "react";
import {IntlProvider} from "react-intl";
import messages_en from "./translations/en.json";
import messages_uk from "./translations/uk.json";
import messages_ru from "./translations/ru.json";
import messages_pt from "./translations/pt.json";

import {Locale, LocalStorage} from "../../utils/types";

export const messagesMap = {
    [Locale.EN]: messages_en,
    [Locale.PT]: messages_pt,
    [Locale.UK]: messages_uk,
    [Locale.RU]: messages_ru,
};

export const TranslationHookContext = React.createContext<UseTranslationReturns>({
    locale: Locale.EN,
    handleLocale: () => {},
});

const TranslationProvider: React.FC = ({children}) => {
    const translationHook = useTranslation();

    return (
        <IntlProvider
            locale={translationHook.locale}
            defaultLocale={Locale.EN}
            messages={messagesMap[translationHook.locale]}
        >
            <TranslationHookContext.Provider value={translationHook}>{children}</TranslationHookContext.Provider>
        </IntlProvider>
    );
};

export default TranslationProvider;

export interface UseTranslationReturns {
    locale: Locale;
    handleLocale(locale: Locale): void;
}

const useTranslation = (): UseTranslationReturns => {
    const [locale, setLocale] = useState<Locale>(Locale.EN);

    useEffect(() => {
        const initialLocale = getInitialLocale();
        setLocale(initialLocale);
        localStorage.setItem(LocalStorage.LOCALE, initialLocale);
    }, []);

    const handleLocale = (locale: Locale) => {
        setLocale(locale);
        localStorage.setItem(LocalStorage.LOCALE, locale);
    };

    return {
        locale,
        handleLocale,
    };
};

const getInitialLocale = (): Locale => {
    const navigatorLanguage = navigator.language;
    const storageLocale = localStorage.getItem(LocalStorage.LOCALE) as Locale;
    const navigatorLocale = navigatorLanguage ? (navigatorLanguage.slice(0, 2) as Locale) : Locale.EN;

    const preInitialLocale = storageLocale ? storageLocale : navigatorLocale;

    const isExistedLocale = Object.values(Locale).some(k => k === preInitialLocale);
    return isExistedLocale && preInitialLocale ? preInitialLocale : Locale.EN;
};
