import React from "react";
import styled from "styled-components";
import {COLOR} from "../../../../../../utils/constants";
import {Menu, MenuItem} from "@material-ui/core";
import {Route} from "../../../../../../utils/types";
import {FormattedMessage} from "react-intl";
import {Button as MuiButton} from "@material-ui/core";
import {NavigationLinkS} from "../../../styled";

const MobileNavWrap = styled.div``;
const Button = styled(MuiButton)`
    && {
        color: ${COLOR.text};
    }
`;

function MobileNavView() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <MobileNavWrap>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <FormattedMessage id={"menu"} />
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <NavigationLinkS to={Route.PRIVACY_POLICY}>
                        <FormattedMessage id={"privacy"} />
                    </NavigationLinkS>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <NavigationLinkS to={Route.TERMS}>
                        <FormattedMessage id={"terms"} />
                    </NavigationLinkS>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <NavigationLinkS to={Route.CONTACT}>
                        <FormattedMessage id={"contact"} />
                    </NavigationLinkS>
                </MenuItem>
            </Menu>
        </MobileNavWrap>
    );
}

export default MobileNavView;
