export const RU_PRIVACY_POLICY = `
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Добро пожаловать в политику конфиденциальности TapeMeet. Спасибо, что нашли время для того, чтобы ознакомиться с ней.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для начала, вам нужно понять, какую информацию мы собираем, для чего мы её собираем, как мы ее используем, и какой у вас есть выбор в отношении использования вашей информации.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ДАТА ВСТУПЛЕНИЯ В СИЛУ:</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;01.08.2021 г.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для пользователей из Калифорнии</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ознакомьтесь с нашим</span><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="/california-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Положение о конфиденциальности в Калифорнии</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, чтобы узнать о правах в отношении конфиденциальности в Калифорнии.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Кто мы</span>
</h3>
<p dir="ltr"
   style="line-height:1.7148;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 15pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">В качестве операторов персональных данных, собираемых и обрабатываемых в рамках приложения TapeMeet, выступает физическое лицо Сергей Лизун. Обозначаемый в тексте настоящей Политики как &laquo;мы&raquo;, &laquo;нас</span><span
        style="font-size:13pt;font-family:Roboto,sans-serif;color:#000910;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&raquo;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p dir="ltr"
   style="line-height:1.7148;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 15pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Вы можете связаться с уполномоченным по защите персональных данных TapeMeet, написав на&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a>
</p>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:-1pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. Где применяется наша Политика конфиденциальности</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Данная политика конфиденциальности распространяется на приложение TapeMeet.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. Информация, которую мы собираем</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Само собой разумеется, что мы не сможем помочь вам искать знакомства, если не будем располагать некоторой информацией о вас, например, основные данные профиля и характеристики людей, с которыми вы хотите познакомиться. Мы также собираем информацию,</span><span
        style="font-size:13pt;font-family:Arial;color:#000910;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">которая генерируется при использовании вами наших сервисов, например, журналы регистрации доступа, а также информацию от третьих сторон, например, когда вы входите на наши сервисы через учетную запись социальной сети. Если вы хотите получить дополнительную информацию, то мы более подробно расскажем об этом&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000910;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ниже.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:2pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Информация, которую вы нам предоставляете</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">При использовании TapeMeet, вы предоставляете нам определенную информацию. К ней относится:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда вы создаете учетную запись, вы, как минимум предоставляете нам свои имя пользователя и пароль, а также некоторую основную информацию, необходимую для работы приложения, например, ваш пол и дату рождения.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">При заполнении своего профиля, вы можете предоставить нам дополнительную информацию, например о вашем характере, образе жизни, интересах и прочую информацию, а также фото и видео. Для добавления определенного контента, например, изображений или видео, вы можете предоставить нам доступ к вашей камере или фотоальбому. Некоторая информация, которую вы можете предоставить нам, считается особой или конфиденциальной в некоторых странах, например ваше расовое или этническое происхождение, сексуальная ориентация и религиозные верования. Предоставляя нам эту информацию, вы соглашаетесь на ее обработку с нашей стороны.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда вы подписываетесь на платные услуги (а не через такие платформы, как iOS или Android), вы предоставляете нам или платежной системе такую информацию, как номер вашей дебетовой или кредитной карты и прочую финансовую информацию.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000910;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда вы участвуете в опросах фокус-групп, вы делитесь с нами своим мнением о наших продуктах и услугах, ответами на наши вопросы и отзывами</span><span
                style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда вы участвуете в наших акциях, мероприятиях или конкурсах, мы собираем информацию, которую вы предоставляете при регистрации или входе в систему.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда вы связываетесь с нашим центром клиентской поддержки, мы собираем информацию, которую вы нам предоставляете во время разговора. Иногда мы отслеживаем или записываем разговоры в учебных целях и для того, чтобы обеспечить высокое качество оказываемых услуг.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Если вы попросите нас связаться с кем-либо или иным образом обработать информацию о других людях (например, если вы попросите нас отправить электронное письмо от вашего имени одному из ваших друзей), мы собираем информацию, которую вы нам предоставляете о других людях, для того, чтобы выполнить вашу просьбу.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Конечно, мы также обрабатываем ваше общение в чате с другими пользователями и контент, который вы публикуете, для обеспечения функционирования приложения.</span>
        </p>
    </li>
</ul>
<h4 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 2pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Информация, которую мы получаем от других лиц</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">В дополнение к информации, которую вы напрямую нам предоставляете, мы также получаем информацию о вас от других лиц, включая:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Другие пользователи.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Другие пользователи могут предоставлять информацию о вас при использовании нашего приложения. Например, мы можем собирать информацию о вас от других пользователей, если они свяжутся с нами, чтобы предоставить ее.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Социальные сети.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Вы можете использовать данные своей учетной записи в социальных сетях (например, имя пользователя и пароль Facebook) для создания аккаунта TapeMeet и входа в него. Тогда у вас не будет необходимости запоминать еще одно имя пользователя и пароль, однако в таком случае мы получим некоторую информацию с вашей страницы в социальной сети.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Другие партнеры.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Мы можем получать информацию о вас от наших партнеров, например, при публикации рекламы TapeMeet на партнерских сайтах и платформах (в данном случае они могут передавать информацию об успешности рекламной кампании).</span>
        </p>
    </li>
</ul>
<h4 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 2pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Информация, собираемая при использовании наших сервисов</span>
</h4>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:8pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда вы используете наше приложение, мы собираем информацию о том, какие функции вы использовали, как вы использовали их, а также с помощью каких устройств вы получили доступ к приложению. Смотрите ниже для получения более подробной информации.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Информация об использовании.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Мы собираем информацию об использовании вами нашего приложения: каким образом вы их используете (дата и время входа в систему, функции, которые вы используете, поиск, клики и страницы, которые отображаются для вас, адреса страниц, с которых вы были перенаправлены, реклама, на которую вы нажимаете) и как вы общаетесь с другими пользователями (например, с пользователями, с которыми вы связываетесь и взаимодействуете, время и дата контакта, количество сообщений, которые вы получаете и отправляете).</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Информация об устройстве.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Мы собираем информацию с устройств, с помощью которых вы используете наше приложение, включая:</span>
        </p>
        <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li aria-level="2" dir="ltr"
                style="list-style-type:circle;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                <p dir="ltr"
                   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">информацию об аппаратном и программном обеспечении, например, IP-адрес, идентификатор устройства и его тип, специальные настройки устройства и приложений, а также характеристики, сбои в работе приложений, рекламные идентификаторы (например, AAID для Google и IDFA для Apple, оба из которых являются произвольно генерируемыми числами, которые вы можете сбросить в настройках вашего устройства), тип браузера, версия и язык, операционная система, часовые пояса, идентификаторы, связанные с cookies или другие технологии, которые могут идентифицировать ваше персональное устройство или браузер (например, IMEI/UDID и адрес MAC);</span>
                </p>
            </li>
            <li aria-level="2" dir="ltr"
                style="list-style-type:circle;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                <p dir="ltr"
                   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">информацию о вашем беспроводном или мобильном интернет-соединении, например о провайдере и мощности сигнала;</span>
                </p>
            </li>
            <li aria-level="2" dir="ltr"
                style="list-style-type:circle;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                <p dir="ltr"
                   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
                    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">информацию о датчиках вашего устройства, таких как акселерометры, гироскопы и компасы.</span>
                </p>
            </li>
        </ul>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Другая информация, предоставляемая с вашего согласия.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Получив от вас разрешение, мы можем определять ваше точное местоположение (широту и долготу) с помощью разных средств, выбор которых зависит от того, какие сервисы и устройства вы используете ( GPS, подключения Bluetooth или Wi-Fi). Сбор данных о местоположении может происходить в фоновом режиме, даже когда вы не пользуетесь нашими услугами, если вы дали нам разрешение на сбор этой информации. Если вы не дадите нам разрешения на получение информации о вашем местоположении, сбор соответствующей информации не производится. Подобным образом, при наличии вашего согласия мы можем собирать ваши фото и видео (например, после публикации вами фотографий, видео или прямой трансляции в нашем приложении).</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. Cookies и другие подобные технологии сбора данных</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы используем и можем позволять другим использовать cookie и подобные им технологии (например, веб-маяки, пиксели), чтобы распознавать вас и ваши устройства. Вы можете прочитать нашу</span><span
        style="font-size:13pt;font-family:Arial;color:#ff0000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="/cookie-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookie Policy</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;для получения более подробной информации о том, почему мы используем их (например, для вашей идентификации, запоминания ваших предпочтений и настроек, анализа трафика сайта и тенденций, реализации и оценки эффективности рекламных кампаний, чтобы вы могли использовать функции социальных сетей) и как вы можете контролировать их использование с помощью настроек.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;" id="section-5">5. Как мы используем информацию</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Основная причина, по которой мы используем вашу информацию, - это предоставление и улучшение наших сервиса. Кроме того, мы используем вашу информацию в целях обеспечения вашей безопасности и предоставления рекламы, которая может вас заинтересовать. Узнайте более подробную информацию, относительно причин, по которым мы используем вашу информацию, а также примеры из практики.</span>
</p>
<h4 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:0pt;margin-bottom:2pt;padding:2pt 0pt 0pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Чтобы управлять вашей учетной записью и предоставлять вам свои сервисы</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Создавать вашу учетную запись и управлять ею</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Оказывать вам клиентскую поддержку и отвечать на ваши запросы</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Проводить ваши транзакции</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Обсуждать с вами наши сервисы, включая управление заказами и выставление счетов</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Помочь вам общаться с другими пользователями</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Анализировать ваш профиль, активность в приложении, а также ваши предпочтения, чтобы рекомендовать вам серьезные знакомства и отображать ваш профиль подходящим пользователям.&nbsp;</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Показывать профили пользователей друг другу</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Обеспечивать бесперебойную работу на ваших устройствах</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Связывать различные устройства, которые вы используете, чтобы обеспечить бесперебойную работу наших сервисов на всех этих устройствах. Мы делаем это, связывая устройства и данные браузера, например, при входе в вашу учетную запись на разных устройствах или с использованием частичного или полного IP-адреса, версии браузера и аналогичных данных о ваших устройствах, чтобы помочь идентифицировать и связать их.</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для предоставления вам новых услуг TapeMeet</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Регистрация и отображение вашего профиля в новых функциях и приложениях TapeMeet</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Администрирование вашей учетной записи в этих новых функциях и приложениях</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Предоставлять вам соответствующие предложения и рекламу</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Организовывать розыгрыши, конкурсы, предоставлять скидки или другие предложения</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Разрабатывать, отображать и отслеживать контент и рекламу, адаптированные к вашим интересам, на наших сервисах и других сайтах</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Общаться с вами по электронной почте, телефону, социальным сетям или мобильному устройству о продуктах или услугах, которые, по нашему мнению, могут вас заинтересовать</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Улучшать наши сервисы и разрабатывать новые</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Управлять фокус-группами и опросами</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Проводить исследования и анализ поведения пользователей для улучшения наших сервисов и контента (например, мы вправе изменить внешний вид или даже существенно изменить определенную функцию на основании поведения пользователей)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Разрабатывать новые функции и сервисы (например, мы можем создать новую функцию, основанную на интересах, в результате запросов, полученных от пользователей).</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Предотвращать, обнаруживать и бороться с мошенничеством или другими незаконными или несанкционированными действиями</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Устранять текущие или предполагаемые нарушения на платформе и вне ее.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Выполнять анализ данных для лучшего понимания и разработки ответных мер в отношении этих действий</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Сохранять данные, связанные с мошенническими действиями, чтобы предотвратить их повторение</span>
        </p>
    </li>
</ul>
<h4 dir="ltr" style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:2pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Обеспечивать юридическое соответствие</span>
</h4>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Соблюдать требования законодательства</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Помогать правоохранительным органам</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Соблюдать или реализовывать наши права, например, наши Условия</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Чтобы обработать вашу информацию, как описано выше, мы полагаемся на следующие правовые основы:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Предоставление вам нашего сервиса.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;В большинстве случаев причина, по которой мы обрабатываем вашу информацию - это выполнение условий договора, заключенного с вами. Например, по мере использования вами TapeMeet для создания серьезных знакомств, мы используем вашу информацию для обслуживания вашей учетной записи и вашего профиля, чтобы сделать ее доступной для просмотра другим пользователям и рекомендовать других пользователей вам.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Законные интересы.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Мы можем использовать вашу информацию, если у нас есть законные интересы. Например, мы анализируем поведение пользователей в приложении с целью непрерывного улучшения и вносим предложения, которые, как мы думаем, могут вас заинтересовать, а также обрабатываем информацию для административных целей, обнаружения мошенничества и других юридических целей.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Согласие.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Иногда мы, по определенным причинам, можем запрашивать ваше согласие на использование вашей информации. Вы можете отозвать свое согласие в любое время, обратившись к нам по адресу, указанному в конце настоящей политики конфиденциальности.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;" id="section-6">6. Как мы делимся информацией</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:6pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Поскольку наша цель состоит в том, чтобы помочь вам в создании серьезных знакомств, основной обмен информацией происходит с другими пользователями. Мы также предоставляем некоторую информацию о пользователях поставщикам услуг и партнерам, которые помогают нам в обслуживании сервиса и в некоторых, случаях судебным инстанциям. Читайте дальше, чтобы узнать больше о предоставлении вашей информации другим пользователям.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Предоставление вашей информации другим пользователям</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Вы делитесь информацией с другими пользователями, когда добровольно раскрываете информацию в приложении (включая общедоступный профиль). Будьте осторожны с предоставлением вашей информации и убедитесь, что контент, которым вы делитесь, &nbsp;это то, что вам удобно публиковать, поскольку ни вы, ни мы не можем контролировать то, что другие сделают с вашей информацией после того, как вы разместите ее.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Если вы решите ограничить аудиторию для всего своего профиля или его части, для определенного контента или информации о вас, тогда он будет отображаться в соответствии с вашими настройками.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Предоставление вашей информации нашим поставщикам услуг и партнерам</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Прибегаем к услугам третьих сторон для поддержки наших операций и улучшения нашего приложения. Эти третьи стороны помогают нам в решении различных задач, включая размещение и обслуживание данных, аналитику, обслуживание клиентов, маркетинг, рекламу, обработку платежей и операций по обеспечению безопасности.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы также можем предоставлять информацию партнерам, которые распространяют и помогают нам рекламировать TapeMeet. Например, мы можем предоставлять ограниченную информацию о вас рекламным партнерам в хешированном, нечитаемом формате.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы проводим строгую проверку перед тем, как привлечь поставщика услуг или начать работать с партнером. Все наши поставщики услуг и партнеры должны согласиться с соблюдением строгих обязательств в отношении конфиденциальности.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для корпоративных транзакций</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы можем передавать вашу информацию, если мы участвуем, полностью или частично, в слиянии, продаже, приобретении, разделении, реструктуризации, реорганизации, роспуске, банкротстве или других изменениях, связанных с правом собственности или управлением.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Когда это требуется по закону</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы можем раскрыть вашу информацию, если это необходимо, чтобы:&nbsp;</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">а) исполнять судебные решения, такие как судебный приказ, повестка в суд или ордер на обыск, расследование по линии правительства/правоохранительных органов или другие правовые требования;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">б) оказывать помощь в предупреждении или выявлении преступности (в каждом случае соответственно действующему законодательству);&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">в) для защиты безопасности пользователя.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Обеспечение законных прав</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><br></span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы также можем предоставлять информацию:&nbsp;</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">а) если раскрытие информации уменьшит нашу ответственность в действительном или предполагаемом иске;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">б) по мере необходимости защищать наши законные права и законные права наших пользователей, деловых партнеров или других заинтересованных сторон;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">в) чтобы обеспечить соблюдение наших соглашений с вами;&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">г) чтобы расследовать, предотвращать или принимать другие меры в отношении незаконной деятельности, предполагаемого мошенничества или других нарушений.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы можем запросить ваше согласие на предоставление вашей информации третьим лицам. В таком случае мы разъясним, почему мы хотим предоставить информацию.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:2pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы можем использовать и передавать неперсональную информацию (то есть информацию, которая сама по себе не определяет, кем вы являетесь, например, информация об устройстве, общая демография, общие поведенческие данные, геолокация в обезличенной форме), а также персональную информацию в хешированной, нечитаемой форме, при любом из вышеуказанных обстоятельств. Мы также вправе предоставлять ее третьим лицам (в частности рекламодателям), чтобы разрабатывать и размещать целевую рекламу в приложении, веб-сайтах или приложениях третьих лиц, а также анализировать и сообщать о рекламе, которую вы видите. Мы вправе объединить эту информацию с дополнительной неперсональной информацией или персональной информацией в хешированной, нечитаемой форме, собранной из других источников. Более подробную информацию о нашем использовании файлов cookie и аналогичных технологий можно найти в нашей</span><span
                style="font-size:13pt;font-family:Arial;color:#ff0000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
                href="/cookie-policy" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookie Policy</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Трансграничная передача данных</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Предоставление информации, изложенной в</span><span
        style="font-size:13pt;font-family:Arial;color:#ff0000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="#section-6"
        style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Разделе 6</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, иногда включает трансграничную передачу данных, например, в Соединенные Штаты Америки и другие юрисдикции. В качестве примера, когда сервис позволяет пользователям находиться в Европейской экономической зоне (EЭЗ), их персональная информация передается в страны за пределами ЕЭЗ. Мы используем стандартные договорные положения, одобренные Европейской комиссией, или другую соответствующую гарантию для передачи данных из ЕЭЗ в другие страны. Стандартными договорными положениями являются обязательства между компаниями, передающими персональные данные, обеспечивающие защиту конфиденциальности и безопасность ваших данных.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Ваши права</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:6pt 0pt 0pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы хотим, чтобы вы контролировали вашу информацию, поэтому мы предоставили вам следующие инструменты:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#636363;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Инструменты доступа/обновления в сервисе.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Инструменты и настройки аккаунта, посредством которых вы можете получать доступ, исправлять или удалять информацию, предоставленную нам и связанную с вашим аккаунтом. Если у вас возникли вопросы относительно этих инструментов и настроек,</span><span
                style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">обратитесь</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;в наш центр клиентской поддержки</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Разрешения для устройств.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Мобильные платформы имеют системы разрешений для определенных типов данных и уведомлений устройства (разрешения на сервисы телефонной книги, информация о местоположении и всплывающие уведомления). Вы можете изменить настройки на своем устройстве, чтобы разрешить или запретить сбор соответствующей информации или отображение соответствующих уведомлений. Запрет может привести к ограничению функциональности некоторых сервисов.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Удаление.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Вы можете удалить свой аккаунт, используя соответствующую функцию сервиса.</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы хотим, чтобы вы знали о своих правах на конфиденциальность. Вот несколько ключевых моментов, о которых вы должны помнить:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Проверка информации.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Законы, регулирующие обработку персональных данных, могут давать вам право на запрос информации, которую мы храним о вас (в зависимости от юрисдикции это можно назвать правом на доступ и проверку полноты и точности сведений). Вы можете запросить копию своей персональной информации</span><span
                style="font-size:13pt;font-family:Arial;color:#ff0000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">здесь</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Обновление информации.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Если вы считаете, что информация, которой мы располагаем о вас, является неточной или что мы больше не имеем права использовать ее, и если вы хотите запросить ее исправление, удаление или ограничить ее обработку,&nbsp;</span><a
                href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
                style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">свяжитесь</span></a><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;с нами.</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для защиты и безопасности всех наших пользователей мы можем попросить вас предоставить подтверждение личности, прежде чем мы сможем ответить на вышеуказанные запросы.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Имейте в виду, что мы можем отклонить запросы по определенным причинам, в том числе, если запрос является незаконным или может нарушать коммерческую тайну, интеллектуальную собственность, или конфиденциальность другого пользователя. Если вы хотите получать информацию, касающуюся другого пользователя, такую как копии сообщений, полученных от него или через наш сервис, другой пользователь должен связаться с нашим сотрудником по вопросам конфиденциальности, чтобы предоставить свое письменное согласие до того, как информация будет опубликована.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Кроме того, мы не сможем удовлетворить определенные запросы, несущие возражения против обработки персональной информации, особенно в тех случаях, когда такие запросы не позволят нам предоставлять вам наши сервисы. Например, мы не можем предоставить вам наш сервис, если у нас нет вашей даты рождения.</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Удаление приложения.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Вы можете остановить сбор информации приложением, удалив его стандартным для вашего устройства образом. Если вы удалите приложение со своего мобильного устройства, уникальный идентификатор, связанный с вашим устройством, будет сохранен. После установки приложения на том же устройстве мы сможем повторно связать этот идентификатор с вашими предыдущими транзакциями и действиями.</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ответственность.</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;В некоторых странах, в том числе в Европейском союзе, вы имеете право подать жалобу в соответствующий орган по защите данных, если у вас есть сомнения относительно того, как мы обрабатываем вашу персональную информацию. Орган, контролирующий обработку персональных данных, находиться по месту вашего жительства, работы или по месту нашей регистрации.</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. Резиденты Калифорнии</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Если вы являетесь резидентом Калифорнии, вы можете запросить раскрывающее уведомление о видах персональной информации о вас, которые мы предоставили третьим лицам в целях их прямого маркетинга в течение предыдущего календарного года. Чтобы запросить это уведомление, отправьте свой запрос&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">здесь</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">. Срок ответа на запрос - 30 дней. Для вашей защиты и защиты всех наших пользователей мы вправе попросить вас предоставить подтверждение личности, прежде чем мы сможем ответить на такой запрос.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10. Как мы защищаем ваши данные</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы прилагаем все усилия, чтобы защитить вас от несанкционированного доступа или изменения, раскрытия или уничтожения вашей персональной информации. Как и во всех технологических компаниях, хотя мы и предпринимаем шаги для обеспечения безопасности вашей информации, мы не обещаем, и вы не должны ожидать, что ваша персональная информация всегда будет оставаться в безопасности.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы регулярно проверяем наши системы по вопросам возможных уязвимостей и атак, и регулярно проверяем наши методы сбора, хранения и обработки информации, чтобы обновлять наши физические, технические и организационные меры безопасности.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы вправе приостановить использование вами приложения без уведомления, если мы подозреваем или обнаруживаем какое-либо нарушение безопасности. Если вы считаете, что ваша учетная запись или информация больше не защищены, немедленно сообщите нам&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">здесь</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11. Как долго мы храним ваши данные</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы храним ваши данные до тех пор, пока они необходимы нам для законных коммерческих целей (как указано в&nbsp;</span><a
        href="#section-5"
        style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Разделе 5</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">) и согласно применимым законодательным требованиям. Чтобы обеспечить защиту и безопасность наших пользователей внутри и вне приложения, мы реализуем &quot;окно безопасности&quot; хранения данных - три месяца после удаления учетной записи. В течение этого периода информация учетной записи будет сохранена, хотя учетная запись, конечно, не будет видна в TapeMeet.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">На практике мы удаляем или анонимизируем вашу информацию при удалении вашей учетной записи (после окна обеспечения безопасности) или после двух лет непрерывного бездействия, за исключением случаев, когда:</span>
</p>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы должны хранить ее в соответствии с действующим законодательством (например, некоторые &quot;данные о трафике&quot; хранятся в течение одного года для соблюдения обязательств по сохранению данных в соответствии с законодательством);</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Мы должны хранить ее в качестве подтверждения соблюдения нами действующего законодательства (например, записи о согласии с нашими условиями, политикой конфиденциальности и другими аналогичными соглашениями сохраняются в течение пяти лет);</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Существует нерешенный вопрос, требование или спор, требующий от нас хранить соответствующую информацию до их разрешения; или</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:decimal;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
            <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Информация должна храниться для наших законных деловых интересов, таких как предотвращение мошенничества и повышение защиты и безопасности пользователей. Например, может потребоваться информация, чтобы не допустить открытие новой учетной записи пользователем, который был лишен прав пользования из-за небезопасного поведения или инцидентов безопасности.</span>
        </p>
    </li>
</ol>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Имейте в виду, несмотря на то, что наши системы предназначены для выполнения процессов удаления данных в соответствии с вышеприведенными рекомендациями, мы не можем обещать, что все данные будут удалены в течение определенного периода времени из-за технических ограничений.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12. Защита конфиденциальности детей</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Наши сервисы предоставляются только пользователям в возрасте 18 лет и старше. Мы не разрешаем лицам в возрасте до 18 лет пользоваться нашей платформой, и мы сознательно не собираем персональную информацию от лиц моложе 18 лет. Если вы подозреваете, что пользователь моложе 18 лет, используйте механизм отчетности.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13. Изменения в политике конфиденциальности</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Поскольку мы всегда ищем новые и инновационные способы поддержки в создании серьезных знакомств, эта политика со временем может меняться. Мы уведомим вас, прежде чем какие-либо существенные изменения вступят в силу, чтобы у вас было время просмотреть изменения.</span>
</p>
<h3 dir="ltr"
    style="line-height:1.464;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:4pt 0pt 4pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">14. Наша контактная информация</span>
</h3>
<p><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Если у вас есть вопросы о данной политике конфиденциальности, вы можете связаться с нами:&nbsp;</span><a
        href="mailto:contact@tapemeet.com" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">contact@tapemeet.com</span></a><span
        style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
`;
