import styled from "styled-components/macro";
import {BREAKPOINT} from "./constants";

export const PageWrap = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: center;
    text-align: center;
    max-width: ${BREAKPOINT.tablet + 1}px;
`;
