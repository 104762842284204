import React from "react";
import styled from "styled-components";
import LogoView from "./Logo";
import NavView from "./Nav";
import {COLOR} from "../../../../utils/constants";
import LanguageView from "./Language";

const HeaderWrap = styled.header`
    border-bottom: 1px solid ${COLOR.grayDark};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${COLOR.white};
    min-height: 50px;
    padding: 0 10px;

    position: sticky;
    top: 0;
`;

function HeaderView() {
    return (
        <HeaderWrap>
            <LogoView />
            <NavView />
            <div>
                <LanguageView />
            </div>
        </HeaderWrap>
    );
}

export default HeaderView;
