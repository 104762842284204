export const UK_COOKIE_POLICY = `
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Вступ</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ця сторінка проінформує тебе про застосування файлів cookie та пояснить, як ними можна керувати.</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Хочеш дізнатися більше про файли cookie і те, як ми їх використовуємо?</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Примітка: політика щодо файлів cookie не описує порядок обробки твоєї особистої інформації поза застосуванням файлів cookie. Докладніше про обробку особистої інформації йдеться в нашій&nbsp;</span><a
        href="/privacy-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Політиці конфіденційності</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Що таке файли cookie?</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Файли cookie &mdash; це невеликі текстові файли, які відправляються до твого браузеру чи до пам&apos;яті твого пристрою або відкриваються через них. Файл cookie зазвичай містить ім&apos;я домену (інтернет-сторінка), з якого він був відправлений, термін дії файлу cookie (коли він закінчується), випадково згенерований індивідуальний номер або подібний ідентифікатор. Файл cookie також може містити інформацію про твій пристрій, наприклад, про налаштування користувача, історію відвідувань і дії, що здійснюються під час користування нашим додатком.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Файли cookie бувають різні?</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Основні та сторонні файли cookie</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Існують основні та сторонні файли cookie. Основні файли на твій пристрій розміщуємо ми. Наприклад, ми використовуємо основні файли cookie, щоб змінити мову нашого сайту на вибрану тобою мову браузера та щоб краще розуміти, як ти користуєшся сайтом. А сторонні файли cookie на твій пристрій розміщують наші партнери та постачальники послуг.&nbsp;</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Сесійні та постійні файли cookie</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Існують сесійні та постійні файли cookie. Сесійні cookie зберігаються у тимчасовій пам&apos;яті та видаляються після закриття браузера. Ми використовуємо сесійні cookie з різних причин, зокрема для того, щоби більше дізнатися, як ти користуєшся нашим сайтом під час окремих сеансів, і допомогти тобі користуватися ним більш ефективно. Постійні cookie існують довше однієї сесії у браузері. Такі файли cookie можуть використовуватися для швидкого повторного входу на вебсайт, аналітичних цілей та з інших причин, які описані нижче.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">А щодо інших технологій відстеження, таких як веб-маяки чи SDK?</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Інші технології, як-от веб-маяки, посилання з відстеженням або пакети інструментів розробки (SDK), використовуються для тих самих цілей, що й файли cookie. Веб-маяки &ndash; це крихітні графічні файли з унікальними ідентифікаторами, які дозволяють нам дізнаватися, коли хтось відвідує наші сервіси або відкриває надісланий нами електронний лист. Посилання з відстеженням &ndash; це спеціально згенеровані посилання, які допомагають нам зрозуміти, звідки надходить трафік до наших вебсторінок. SDK &ndash; це вбудовані в застосунки частинки коду, які діють як файли cookie чи веб-маяки.</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для простоти в цій політиці щодо файлів cookie ми також називаємо ці технології cookie.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Для чого ми використовуємо файли cookie?</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Як і інші постачальники онлайн-послуг, ми використовуємо файли cookie для постачання, забезпечення та поліпшення наших сервісів, зокрема через такі дії: запам&apos;ятовуємо твої параметри, впізнаємо тебе, коли ти заходиш до застосунку, оцінюємо успіх наших маркетингових кампаній і персоналізуємо рекламу відповідно до твоїх інтересів. Для цих цілей ми також можемо пов&apos;язувати інформацію з файлів cookie з іншою особистою інформацією, яку ми зберігаємо.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Коли ти використовуєш наш додаток, на твій пристрій можуть встановлюватися деякі чи всі наступні види файлів cookie.</span>
</p>
<p><br></p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Основні cookie&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ці файли cookie цілком необхідні для надання наших сервісів, таких як можливість увійти в систему, запам&apos;ятовування твоїх параметрів і захист шляхом виявлення зловмисної активності.</span>
        </p>
    </li>
</ul>
<p><br></p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Аналітичні cookie&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ці файли cookie допомагають нам зрозуміти, як використовуються наші сервіси, щоб ми могли персоналізувати та покращити їх для тебе.</span>
        </p>
    </li>
</ul>
<p><br></p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Рекламні та маркетингові cookie&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ці файли cookie визначають ефективність наших маркетингових кампаній і допомагають нам показувати актуальну для тебе рекламу. Вони виконують наступні функції: допомагають нам зрозуміти, скільки трафіку маркетингові кампанії привертають до наших сервісів, попереджають багаторазовий показ однакової реклами, забезпечують належне відображення реклами, вибирають актуальну для тебе рекламу, вимірюють кількість відображеної реклами та її ефективність (скільки людей взаємодіє з рекламою).</span>
        </p>
    </li>
</ul>
<p><br></p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Сookie соціальних мереж&nbsp;</span><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ці файли cookie використовуються для того, щоб у тебе була можливість ділитися сторінками та цікавим для тебе змістом на наших сервісах через сторонні соціальні мережі й інші вебсайти та сервіси. Вони також можуть використовуватися у рекламних цілях.</span>
        </p>
    </li>
</ul>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Як ти можеш керувати файлами cookie?</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Існує кілька способів для керування файлами cookie. Зверни увагу: якщо змінити параметри файлів cookie, твої враження від нашого додатку можуть бути неповними, тому що вони будуть менш персоналізованими. У деяких випадках ти навіть не зможеш повністю або частково користуватися нашим застосунком.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Керування браузером та пристроєм</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Деякі веб-браузери містять налаштування, які дозволяють тобі контролювати або відмовлятися від cookies, або сповіщають про розміщення cookie на твоєму комп&apos;ютері. Процедура керування cookies трохи відрізняється у кожному інтернет-браузері. Ти можеш перевірити конкретні кроки саме для твого браузера в меню допомоги.</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ти також можеш скинути ідентифікатори пристрою чи відмовитися від їхнього збирання чи обробки, вибравши відповідні налаштування на мобільному пристрої. Керування ідентифікаторами дещо відрізняється на різних пристроях. Конкретні кроки описані в меню допомоги чи налаштуваннях твого пристрою.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Рекламні інструменти на основі інтересів</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Рекламні агенції можуть брати участь у саморегульованих програмах, які дають тобі змогу відмовлятися від будь-якої їхньої реклами на основі інтересів. Докладніше про це йдеться на цих сайтах:&nbsp;</span><a
        href="http://optout.aboutads.info/?c=2#!/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Digital Advertising Alliance</span></a><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">,&nbsp;</span><a
        href="http://www.youronlinechoices.eu/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Interactive Digital Advertising Alliance</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">або&nbsp;</span><a
        href="http://youradchoices.com/appchoices" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Appchoices</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;(тільки для застосунків).</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Відмова не означатиме, що ти не бачитимеш рекламу зовсім. Тобі просто не показуватимуть персоналізовану рекламу від компаній, які беруть участь у таких програмах. Окрім того, якщо після відмови ти видалиш файли cookie на своєму пристрої, тобі доведеться відмовитися від персоналізованої реклами знову.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Файли cookie Google&trade;</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Файли cookie Google&trade; Maps API</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Файли cookie Google&trade; Maps API Деякі функції нашого вебсайту та деякі сервіси TapeMeet залежать від використання файлів cookie Google&trade; Maps API. Ці cookie будуть розміщені на твоєму пристрої.</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Користуючись цим вебсайтом і сервісами, які залежать від файлів cookie Google&trade; Maps API, ти погоджуєшся на зберігання та збирання таких файлів cookie на своєму пристрої, а також надаєш дозвіл на доступ, використання та передачу зібраних таким чином даних компанією Google.</span>
</p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google&trade; здійснює управління інформацією та вашими налаштуваннями стосовно файлів cookie Google&trade; Maps API через окремий інтерфейс, а не через інтерфейс браузера. Детальніше див.&nbsp;</span><a
        href="https://www.google.com/policies/technologies/cookies/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://www.google.com/policies/technologies/cookies/</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Google Analytics</span>
</p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ми використовуємо Google Analytics, який є сервісом Google, що застосовує файли cookie та інші технології збору даних для збору інформації про твоє користування веб-сайтом і сервісами, щоб відстежувати тенденцій користування веб-сайтом.</span>
</p>
<p><br></p>
<p><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Додаткова інформація про збір і обробку даних компанією Google доступна на сторінці&nbsp;</span><a
        href="https://policies.google.com/technologies/partner-sites" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://policies.google.com/technologies/partner-sites</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">. Ти можеш відмовитися від використання Google Analytics, завантаживши відповідне браузерне доповнення на сторінці&nbsp;</span><a
        href="https://tools.google.com/dlpage/gaoptout" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://tools.google.com/dlpage/gaoptout</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, і відмовитися від персоналізації реклами Google на сторінці&nbsp;</span><a
        href="https://adssettings.google.com/" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">https://adssettings.google.com/</span></a><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
`;
