import React, {FC} from "react";
import {
    Box,
    Checkbox as MuiCheckbox,
    CheckboxProps,
    FormControlLabel as MuiFormControlLabel,
    Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import {NotificationEmailCategory} from "../../../../../../utils/types";
import {FormattedMessage} from "react-intl";

const Wrap = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
    && {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 0;
    }
`;

const Label = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    align-items: flex-start;
`;

const Title = styled(Typography)`
    && {
        padding-bottom: 5px;
        font-weight: bold;
    }
`;

const Description = styled(Typography)`
    && {
        font-weight: normal;
        text-align: left;
    }
`;

const Checkbox = styled(MuiCheckbox)``;

type NotificationCategoryViewProps = {
    category: NotificationEmailCategory;
    checked: boolean;
    onChange: CheckboxProps["onChange"];
};

const NotificationCategoryView: FC<NotificationCategoryViewProps> = ({category, checked, onChange}) => {
    const showDescription = category !== NotificationEmailCategory.NEW_MATCHES;

    return (
        <Wrap>
            <FormControlLabel
                label={
                    <Label>
                        <Title variant={`body1`}>
                            <FormattedMessage id={`notification.email.category.${category}.title`} />
                        </Title>
                        {showDescription && (
                            <Description variant={`subtitle2`}>
                                <FormattedMessage id={`notification.email.category.${category}.description`} />
                            </Description>
                        )}
                    </Label>
                }
                name={category}
                labelPlacement="start"
                control={<Checkbox color={"primary"} checked={checked} onChange={onChange} />}
            />
        </Wrap>
    );
};

export default NotificationCategoryView;
