import React, {useContext} from "react";
import styled from "styled-components";
import {Button as MuiButton, Menu, MenuItem, useMediaQuery, ClickAwayListener} from "@material-ui/core";
import {BREAKPOINT, COLOR, LANGUAGE, LOCALE_LANGUAGE} from "../../../../../utils/constants";
import {TranslationHookContext} from "../../../../TranslationProvider";
import {Locale} from "../../../../../utils/types";
import LanguageIcon from "@material-ui/icons/Language";

const LanguageWrap = styled.div`
    color: ${COLOR.text};
`;

const Icon = styled(LanguageIcon)`
    color: ${COLOR.text};
`;

const Button = styled(MuiButton)`
    && {
        color: ${COLOR.text};
    }
`;

function LanguageView() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const languageHookContext = useContext(TranslationHookContext);

    const isMobile = useMediaQuery(`(max-width:${BREAKPOINT.mobile}px)`);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (locale: Locale) => () => {
        setAnchorEl(null);
        languageHookContext?.handleLocale(locale);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    return (
        <LanguageWrap>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <Icon />
                    &nbsp;
                    {!isMobile ? LANGUAGE[languageHookContext.locale] : languageHookContext.locale.toUpperCase()}
                </Button>
            </ClickAwayListener>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClose(Locale.EN)}>{LOCALE_LANGUAGE[Locale.EN]}</MenuItem>
                <MenuItem onClick={handleClose(Locale.PT)}>{LOCALE_LANGUAGE[Locale.PT]}</MenuItem>
                <MenuItem onClick={handleClose(Locale.UK)}>{LOCALE_LANGUAGE[Locale.UK]}</MenuItem>
                <MenuItem onClick={handleClose(Locale.RU)}>{LOCALE_LANGUAGE[Locale.RU]}</MenuItem>
            </Menu>
        </LanguageWrap>
    );
}

export default LanguageView;
