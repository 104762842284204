import React from "react";
import HeaderView from "./components/common/Header";
import {createGlobalStyle} from "styled-components";
import {COLOR} from "../utils/constants";
import Routes from "./Routes";
import {BrowserRouter as Router} from "react-router-dom";
import TranslationProvider from "./TranslationProvider";
import {ThemeProvider} from "@material-ui/core";
import {theme} from "../utils/theme";
import FooterView from "./components/common/Footer";

const GlobalStyle = createGlobalStyle`
    html {
        font-size: 16px;
    }
  body {
    color: ${COLOR.text};
  }
`;

function App() {
    return (
        <ThemeProvider theme={theme}>
            <TranslationProvider>
                <Router>
                    <GlobalStyle />
                    <HeaderView />
                    <Routes />
                    <FooterView />
                </Router>
            </TranslationProvider>
        </ThemeProvider>
    );
}

export default App;
