export const CALIFORNIA_POLICY = `
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For California Consumers</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This California section supplements the</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="/privacy-policy" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Privacy Policy</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">and applies solely to California consumers . The Table below describes how we process California consumers&rsquo; personal information, based on definitions laid out in the California Consumer Privacy Act (&quot;CCPA&quot;).</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Not all of the items listed below are relevant to you.</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;For example, we do not collect Social Security numbers from our users, but we may collect this data in the course of evaluating a job applicant. Note: The categories of data below stem from a statutory list set forth under the CCPA.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The items listed below are categories of personal information ; sources of collection and business or commercial purposes for which the personal information was collected over the 12-month period prior to the effective date of our Privacy Policy (in addition to the purposes listed in the Privacy Policy)</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    &nbsp;</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Identifiers such as a real name, alias, postal address, unique personal identifier such as a device identifier; cookies, beacons, pixel tags, mobile ad identifiers and similar technology; customer number, unique pseudonym, or user alias; telephone number and other forms of persistent or probabilistic identifiers, online identifier, Internet Protocol address, email address, account name, Social Security number, driver&rsquo;s license number, passport number, and other similar identifiers</span>
</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection&nbsp;</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (for instance if you create an account or apply for a position)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From social media networks (for example if you log into our app through an account you have on a social media network)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From partners (for example, where our ads promoting our services are published on a partner&apos;s website or where a partner or consumer reporting agency shares with us information about bad actors)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From our affiliated companies (notably for anti-fraud and safety purposes)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other users as part of the general operation of the app</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Others, upon the instruction of a user using a profile sharing functionality</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our affiliates (in particular to prevent, detect and fight fraud or other illegal or unauthorized activities under our Terms)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our payment service providers and fraud vendors (e.g., for anti-fraud purposes and to fight against other illegal activities)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Joint marketing partners</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:10pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. Signature, physical characteristics or description, state identification card number, education, bank account number, credit card number, debit card number, other financial information, and medical information</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection&nbsp;</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (for instance if you create an account or apply for a position)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From our affiliated companies (notably for anti-fraud and safety purposes)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other users as part of the general operation of the app</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Others, upon the instruction of a user using a profile sharing functionality</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our affiliates (in particular to prevent, detect and fight fraud or other illegal or unauthorized activities under our Terms)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our payment service providers and fraud vendors (e.g., for anti-fraud purposes and to fight against other illegal activities)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Joint marketing partners</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:10pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. Characteristics of protected classifications under California or federal law, such as race, color, national origin, religion, age, sex, gender, gender identity, gender expression, sexual orientation, marital status, medical condition, disability, citizenship status, and military and veteran status</span>
</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection&nbsp;</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (for instance if you create an account or apply for a position)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From consumer reporting agencies if they share with us information about bad actors</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From social media networks (for example if you log into our app through an account you have on a social media network)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From our affiliated companies (notably for anti-fraud and safety purposes)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other users as part of the general operation of the app</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Others, upon the instruction of a user using a profile sharing functionality</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our affiliates (in particular to prevent, detect and fight fraud or other illegal or unauthorized activities under our Terms)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our payment service providers and fraud vendors (e.g., for anti-fraud purposes and to fight against other illegal activities)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Joint marketing partners</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. Commercial information, including records of personal property, products or services purchased, obtained, or considered, and other purchasing or consuming histories or tendencies</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:10pt 0pt 0pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection&nbsp;</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (if you purchase something from us)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our payment service providers and fraud vendors (e.g., for anti-fraud purposes and to fight against other illegal activities)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:10pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5. Biometric information</span>
</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection&nbsp;</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (if you use our photo verification feature)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendors and professional services organizations who assist us in relation to the operation of the feature</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;margin-left: 36pt;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
    &nbsp;</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6. Internet and other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding your interaction with websites, applications or advertisements</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:10pt 0pt 0pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (for example, logs generated as you use our services)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From users of our services (for instance through other users&apos; interactions with you)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From advertising partners (for example, where our ads promoting our services are published on a partner&apos;s website)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    &nbsp;</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Geolocation data</span>
</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (if you consent that we collect and use your geolocation as you use our app)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Audio, electronic, visual, and similar information</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:10pt 0pt 0pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (for instance the photo(s) you upload when you create an account with us)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From social media networks (for example if you upload photos from one of your accounts on a social media network)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From consumer reporting agencies if they share with us information about bad actors</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From our affiliated companies (notably for anti-fraud and safety purposes)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other users as part of the general operation of the app</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Others, upon the instruction of a user using a profile sharing functionality</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Our affiliates (in particular to prevent, detect and fight fraud or other illegal or unauthorized activities under our Terms)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Joint marketing partners</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. Professional or employment-related information</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:10pt 0pt 0pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From you (for instance if you apply for a position at or provide services to us or if you say who your employer is in your profile)</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">From others (for instance from people who may be working with you as you provide services to us or who may participate to the hiring process)</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other users as part of the general operation of the app</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Others, upon the instruction of a user using a profile sharing functionality</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10. Inferences drawn from any of the information identified above to create a profile about you reflecting your preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:10pt 0pt 0pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sources of collection</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Created from data in your profile</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:10pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Categories of third parties with whom the business shared or disclosed the personal information for a business purpose</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein</span>
        </p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.764;margin-left: 36pt;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
    &nbsp;</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Business or commercial purposes for which the personal information was collected over the 12-month period prior to the effective date of our Privacy Policy (in addition to the purposes listed in the Privacy Policy)</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Performing services, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytics services, or providing similar services</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Auditing related to a current interaction with you and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Short-term, transient use, including, but not limited to, the contextual customization of ads shown as part of the same interaction</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Debugging to identify and repair errors that impair existing intended functionality</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">undertaking internal research for technological development and demonstration</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by us, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by us</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">managing our career opportunities</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">establishing and managing our relationships with our vendors and consultants</span>
        </p>
    </li>
</ul>
<h3 dir="ltr"
    style="line-height:1.464;background-color:#ffffff;margin-top:14pt;margin-bottom:0pt;padding:0pt 0pt 4pt 0pt;"><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">California Consumer Privacy Rights</span>
</h3>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:6pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We do not sell your personal information so no opt-out choice is necessary.</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;What this means is that we do not sell, rent, release, disclose, disseminate, make available, transfer, or otherwise communicate in any way your personal information to another company for monetary or other valuable consideration.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You have the right to request, twice in a 12-month period, the following information about the personal information we have collected about you during the past 12 months:</span>
</p>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">the categories and specific pieces of personal information we have collected about you;</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">the categories of sources from which we collected the personal information;</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">the business or commercial purpose for which we collected or sold the personal information;</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">the categories of third parties with whom we shared the personal information; and</span>
        </p>
    </li>
    <li aria-level="1" dir="ltr"
        style="list-style-type:disc;font-size:13pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.764;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;"><span
                style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">the categories of personal information about you that we sold or disclosed for a business purpose, and the categories of third parties to whom we sold or disclosed that information for a business purpose.</span>
        </p>
    </li>
</ul>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:10pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You have the right to request that we delete the personal information we have collected from you. If you choose to exercise any of your rights under the CCPA, you have the right to not receive discriminatory treatment.</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How to Submit a Request?</span><span
        style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
</p>
<p dir="ltr"
   style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To submit an access or deletion request,</span><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a
        href="mailto:contact@tapemeet.com" data-mail="support" style="text-decoration:none;"><span
        style="font-size:13pt;font-family:Arial;color:#EC3A48;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">click here</span></a><span
        style="font-size:13pt;font-family:Arial;color:#636363;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.</span>
</p>
<p dir="ltr" style="line-height:1.764;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;">
    <span style="font-size:13pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">To help protect your privacy and maintain security, we take steps to verify your identity before granting you access to your personal information or complying with your request. To the extent permitted by applicable law, we may charge a reasonable fee to comply with your request.</span>
</p>            
`;
