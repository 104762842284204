import React, {FC, PropsWithChildren, useContext} from "react";
import {Box} from "@material-ui/core";
import styled from "styled-components/macro";
import {Social} from "../../../utils/types";
import {getSocialUrls} from "../../../utils/helpers";
import {TranslationHookContext} from "../../TranslationProvider";
import {LogEvent, handleLogEvent} from "../../../firebase";

const Wrap = styled(Box)`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
`;

const SocialWrap = styled(Box)`
    padding: 10px;
`;

const SocialImg = styled.img``;

const SocialView: FC<PropsWithChildren<unknown>> = props => {
    const {locale} = useContext(TranslationHookContext);

    const socialUrls = getSocialUrls({locale});

    const onClick = (social: Social) => () => {
        handleLogEvent(LogEvent.CLICK_SOCIAL, {
            timestamp: new Date().toISOString(),
            social,
        });
    };

    return (
        <Wrap {...props}>
            {Object.values(Social).map(social => {
                const href = socialUrls[social];
                const icon = socialUrls[`${social}Icon`];

                return (
                    <SocialWrap key={social}>
                        <a href={href} target={"_blank"} rel="noreferrer" onClick={onClick(social)}>
                            <SocialImg width={44} height={44} src={icon} alt={social} />
                        </a>
                    </SocialWrap>
                );
            })}
        </Wrap>
    );
};

export default SocialView;
